import { useNavigate } from 'react-router-dom'
// move this to another file
import Highcharts from 'highcharts'
import HT from 'highcharts/highcharts-more'
import HighchartsReact from 'highcharts-react-official'

import { useGeoState } from '../../../../components/GeoProvider'
import Spinner from '../../../../components/Loader/Spinner'
import Icon from '../../../../components/Icon'

import statesMap from '../../../../utils/stateMap'
import cbsasMap from '../../../../utils/cbsasMap'
import countyMap from '../../../../utils/cbsasMap'
import { epiweekToolTip } from './toolTipFormatterFlu'
import modelOptions from './fluModelOptions'
import AccuracyTable from '../../../../components/AccuracyTable'
import styles from './FluDetailedView.module.scss'

if (typeof Highcharts === 'object') {
  HT(Highcharts)
}
const DetailedViewDisplay = ({
  cbsaData,
  statesData,
  type,
  identifier,
  cbsaGraphData,
  stateGraphData,
  msaData,
  countyData,
}) => {
  const { dataSelected } = useGeoState()
  let dataObj = []
  if (type === 'state') {
    Object.entries(statesData).forEach(function (dateObj) {
      const key = dateObj[0]
      const value = dateObj[1][identifier]
      dataObj.push({ date: key, ...value })
    })
  } else if (type === 'msa') {
    Object.entries(msaData).forEach(function (dateObj) {
      const key = dateObj[0]
      const value = dateObj[1][identifier]
      dataObj.push({ date: key, ...value })
    })
  } else if (type === 'county') {
    Object.entries(countyData).forEach(function (dateObj) {
      const key = dateObj[0]
      const value = dateObj[1][identifier]
      dataObj.push({ date: key, ...value })
    })
  }

  // let death = [],
  //   cases = [],
  //   actual_value = [],
  //   percent95_1 = [],
  //   percent95_2 = [],
  //   percent95_3 = [],
  //   percent95_4 = [],
  //   weeks = []
  let revised = [],
  unrevised = [],
  pointforecast = [],
  percent95 = [],
  weeks = []
  // for (var i = 0; i <= 7; i++) {
  //   if (dataSelected === 'revised_accuracy') {
  //     var ci_actual = dataObj.map((d) => {
  //       return [d['1_new_deaths'].actual_value]
  //     })
  //     var ci_95_1 = dataObj.map((d) => {
  //       return [d['1_new_deaths'].lower_value, d['1_new_deaths'].upper_value]
  //     })
  //     var ci_95_2 = dataObj.map((d) => {
  //       return [d['2_new_deaths'].lower_value, d['2_new_deaths'].upper_value]
  //     })
  //     var ci_95_3 = dataObj.map((d) => {
  //       return [d['3_new_deaths'].lower_value, d['3_new_deaths'].upper_value]
  //     })
  //     var ci_95_4 = dataObj.map((d) => {
  //       return [d['4_new_deaths'].lower_value, d['4_new_deaths'].upper_value]
  //     })

  //     var type = dataObj[i]['2_new_deaths'].forecast_type
  //   } else {
  //     var ci_actual = dataObj.map((d) => {
  //       return [d['1_new_cases'].actual_value]
  //     })
  //     var ci_95_1 = dataObj.map((d) => {
  //       return [d['1_new_cases'].lower_value, d['1_new_cases'].upper_value]
  //     })
  //     var ci_95_2 = dataObj.map((d) => {
  //       return [d['2_new_cases'].lower_value, d['2_new_cases'].upper_value]
  //     })
  //     var ci_95_3 = dataObj.map((d) => {
  //       return [d['3_new_cases'].lower_value, d['3_new_cases'].upper_value]
  //     })
  //     var ci_95_4 = dataObj.map((d) => {
  //       return [d['4_new_cases'].lower_value, d['4_new_cases'].upper_value]
  //     })

  //     var type = dataObj[i]['1_new_cases'].forecast_type
  //   }
  //   actual_value.push(ci_actual)
  //   percent95_1.push(ci_95_1)
  //   percent95_2.push(ci_95_2)
  //   percent95_3.push(ci_95_3)
  //   percent95_4.push(ci_95_4)
  //   // percent95_5.push(ci_95_5)
  //   cases.push(type)
  //   ci_actual = null
  //   ci_95_1 = null
  //   ci_95_2 = null
  //   ci_95_3 = null
  //   ci_95_4 = null
  //   type = null
  // }

  for (var i = 1; i <= 6; i++) {
    var per95 = dataObj.map((d) => {
      return [d[i].low95, d[i].high95]
    })
    var rev = dataObj.map((d) => {
      return [d[i].revised_flu]
    })
    var unrev = dataObj.map((d) => {
      return [d[i].unrevised_flu]
    })
    var point = dataObj.map((d) => {
      return [d[i].pointforecast]
    })
    percent95.push(per95)
    revised.push(rev)
    unrevised.push(unrev)
    pointforecast.push(point)

    per95 = null
    rev = null
    unrev = null
    point = null
  }

  // for (var i = 1; i <= 6; i++) {
  //   var per95 = dataObj.map((d) => {
  //     return [d[i], d[i].ci_95]
  //   })
  //   var rev = dataObj.map((d) => {
  //     return [d[i].forecast_type]
  //   })
  //   var unrev = dataObj.map((d) => {
  //     return [d[i].forecast_type]
  //   })
  //   var point = dataObj.map((d) => {
  //     return [d[i].forecast_type]
  //   })
  //   percent95.push(per95)
  //   revised.push(rev)
  //   unrevised.push(unrev)
  //   pointforecast.push(point)

  //   per95 = null
  //   rev = null
  //   unrev = null
  //   point = null
  // }

  // dataObj.map((d) => {
  //   weeks.push(d['1_new_deaths'].epiweek)
  // })
  dataObj.map((d) => {
    weeks.push(d[6].epiweek)  
  })

  let data = []
  if (type === 'state') {
    const statesDataForDisplayDate = Object.entries(
      stateGraphData['ENSEMBLE']
    )
    Object.entries(statesDataForDisplayDate).forEach(function (dateObj) {
      if (dateObj[1][0] === identifier) {
        data.push({
          error_metric: 'MAE',
          revised: dateObj[1][1].OVERALL.revised_mae,
          unrevised: dateObj[1][1].OVERALL.unrevised_mae,
        })
        data.push({
          error_metric: 'MAPE',
          revised: dateObj[1][1].OVERALL.revised_mape,
          unrevised: dateObj[1][1].OVERALL.unrevised_mape,
        })

        data.push({
          error_metric: 'RMSE',
          revised: dateObj[1][1].OVERALL.revised_rmse,
          unrevised: dateObj[1][1].OVERALL.unrevised_rmse,
        })

      }
    })
  }
  // else if (type === 'city') {
  //   const cbsasDataForDisplayDate = Object.entries(
  //     cbsaData[modelOptions[0].value]
  //   )
  //   Object.entries(cbsasDataForDisplayDate).forEach(function (dateObj) {
  //     if (dateObj[1][0] === identifier) {
  //       data.push({
  //         error_metric: 'MAE',
  //         revised: dateObj[1][1].OVERALL.revised_mae,
  //         unrevised: dateObj[1][1].OVERALL.unrevised_mae,
  //       })
  //       data.push({
  //         error_metric: 'MAPE',
  //         revised: dateObj[1][1].OVERALL.revised_mape,
  //         unrevised: dateObj[1][1].OVERALL.unrevised_mape,
  //       })
  //       data.push({
  //         error_metric: 'RMSE',
  //         revised: dateObj[1][1].OVERALL.revised_rmse,
  //         unrevised: dateObj[1][1].OVERALL.unrevised_rmse,
  //       })

  //     }
  //   })
  // }
  else {   
    const cbsasDataForDisplayDate = Object.entries(
      //cbsaData[modelOptions[0].value]
      cbsaGraphData.response_data['ENSEMBLE']      
    )
    Object.entries(cbsasDataForDisplayDate).forEach(function (dateObj) {
      if (dateObj[1][0] === identifier) {
        data.push({
          error_metric: 'MAE',
          revised: dateObj[1][1].OVERALL.revised_mae,
          unrevised: dateObj[1][1].OVERALL.unrevised_mae,
        })
        data.push({
          error_metric: 'MAPE',
          revised: dateObj[1][1].OVERALL.revised_mape,
          unrevised: dateObj[1][1].OVERALL.unrevised_mape,
        })
        data.push({
          error_metric: 'RMSE',
          revised: dateObj[1][1].OVERALL.revised_rmse,
          unrevised: dateObj[1][1].OVERALL.unrevised_rmse,
        })

      }
    })
  }

  const chartOptions = {
    title: {
      text: undefined,
    },
    xAxis: {
      categories: weeks,
      title: {
        text: 'Week of Covid Epiweek',
      },
    },
    yAxis: {
      title: {
        text: '% Covid Intensity',
      },
      min: 0,
    },
    tooltip: {
      crosshairs: true,
      shared: true,
      useHTML: true,
      padding: 0,
      formatter: epiweekToolTip,
    },
    credits: {
      enabled: false,
    },
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
    },
    plotOptions: {
      line: {
        marker: {
          enabled: false,
        },
      },
    },
    series:[
      {
        name: 'Revised',
        data: revised[5],
        zIndex: 1,
        color: '#1192E8',
      },
      // {
      //   name: 'Pointforecast',
      //   data: pointforecast[5],
      //   zIndex: 1,
      //   showInLegend: true,
      //   color: 'pink',
      // },
      {
        name: 'UnRevised',
        data: unrevised[5],
        zIndex: 1,
        color: '#6929C4',
      },

      {
        name: 'Horizon 1',
        data: percent95[0],
        type: 'arearange',
        lineWidth: 0,
        color: '#a4d4ff7a',
        fillOpacity: 0.5,
        zIndex: 0,
        showInLegend: true,
        marker: {
          enabled: false,
        },
      },

      {
        name: 'Horizon 2',
        data: percent95[1],
        type: 'arearange',
        lineWidth: 0,
        color: '#c7baee',
        fillOpacity: 0.25,
        zIndex: 0,
        showInLegend: true,
        marker: {
          enabled: false,
        },
      },

      {
        name: 'Horizon 3',
        data: percent95[2],
        type: 'arearange',
        lineWidth: 0,
        color: '#79e0607a',
        fillOpacity: 0.25,
        zIndex: 0,
        showInLegend: true,
        marker: {
          enabled: false,
        },
      },

      {
        name: 'Horizon 4',
        data: percent95[3],
        type: 'arearange',
        lineWidth: 0,
        color: '#bacaee',
        fillOpacity: 0.25,
        zIndex: 0,
        showInLegend: true,
        marker: {
          enabled: false,
        },
      },      
      {
        name: 'Horizon 5',
        data: percent95[4],
        type: 'arearange',
        lineWidth: 0,
        color: '#cae2c6',
        fillOpacity: 0.25,
        zIndex: 0,
        showInLegend: true,
        marker: {
          enabled: false,
        },
      },

      {
        name: 'Horizon 6',
        data: percent95[5],
        type: 'arearange',
        lineWidth: 0,
        color: '#dfe2e4',
        fillOpacity: 0.25,
        zIndex: 0,
        showInLegend: true,
        marker: {
          enabled: false,
        },
      },
    ],
  }
  return (
    <>
      <div>
        <HighchartsReact
          containerProps={{
            style: {
              width: '100%',
              position: 'relative',
            },
          }}
          highcharts={Highcharts}
          options={chartOptions}
          allowChartUpdate
          updateArgs={[true, true, true]}
        />
      </div>
      <AccuracyTable data={data} />
    </>
  )
}

const CovidDetailedView = ({
  cbsaData,
  statesData,
  hash,
  cbsaGraphData,
  stateGraphData,
  msaData,
  countyData,
  cbsaJson,
  countyJson,
  
}) => {
  const history = useNavigate()
  const area = hash.replace('#', '').split('-')
  const identifier = area[1]
  const type = area[0]
  let name = ''
  let displayType = type

  switch (type) {
    case 'state':
      name = statesMap()[identifier]
      break
    case 'metro':
    case 'city':
    case 'msa':
      name = cbsasMap(cbsaJson)[identifier]
      displayType = 'Metro'
      break
    case 'county':
      name = countyMap(countyJson)[identifier]
      displayType = 'County'
    default:
    // code block
  }

  return (
    <div className={styles.base}>
      <div className={styles.header}>
        <div>
          <h5>{name}</h5>
        </div>
        <button
          onClick={() => {
            history(`/about/model#accuracy`)
          }}
        >
          <Icon type="remove" size={'1rem'} />
        </button>
      </div>
      <div className={styles.body}>
        {type === 'state' ||
        type === 'msa' ||
        (type === 'county' &&
          stateGraphData !== undefined &&
          Object.keys(stateGraphData).length >= 1) ||
        (type === 'metro' &&
          cbsaGraphData !== undefined &&
          Object.keys(cbsaGraphData).length >= 1) ? (
          <DetailedViewDisplay
            cbsaData={cbsaData}
            statesData={statesData}
            msaData={msaData}
            countyData={countyData}
            hash={hash}
            type={type}
            identifier={identifier}
            cbsaGraphData={cbsaGraphData}
            stateGraphData={stateGraphData}
          />
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  )
}

export default CovidDetailedView
