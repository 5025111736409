import { React } from 'react'
import styles from './HeaderTab.module.scss'
import TrendingDown from '../../../assets/images/icon/trending_down.png'
import TrendingUp from '../../../assets/images/icon/trending_up.png'
const HeaderInfo = ({ }) => {

      return (
     
      <div className={styles.topHeader}>
           <div className={styles.topHeaderTitle}>
            <span>Select a condition below.</span>
          
         </div>
       
     
        <div className={styles.topHeaderTab}>
        <div  
                    className={
                    styles.headerTab}>
                    <img
                      src={TrendingDown}
                      alt="arrow-down"
                      style={{ height: '20px', width: '20px', marginBottom: '-3px' }}
                    ></img>
                    <span>Decline in Incidences</span>
                    </div>
                    <div  
                    className={
                    styles.headerTab}>
                    <img
                      src={TrendingUp}
                      alt="arrow-down"
                      style={{ height: '20px', width: '20px', marginBottom: '-3px' }}
                    ></img>
                    <span>Rise in Incidences</span>
                    </div>
        </div>
        </div>
      
      )
    }
    
    export default HeaderInfo
  