import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import RouteProvider from '../RouteProvider'
import pages from '../../pages'

function RouteWrapper({ children }) {
  return <>{children}</>
}
const Router = () => (
  <BrowserRouter>
    <RouteProvider value={pages}>
      {(context) => (
        <Routes>
          {context.routes.map((route, index) => {
            return (
              <Route
                key={index}
                element={<RouteWrapper>{route?.element}</RouteWrapper>}
                path={route.path}
              />
            )
          })}
        </Routes>
      )}
    </RouteProvider>
  </BrowserRouter>
)

export default Router
