import React, { useState, useCallback, useEffect } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useNavigate, useParams, Link } from 'react-router-dom'
import DatePicker from 'react-datepicker'

import Breadcrumbs from '../../../../components/Breadcrumbs'
import Layout from '../../../../components/Layout'
import Content from '../../../../components/Content'
import Flex from '../../../../components/Flex'
import Label from '../../../../components/Label'
import TextBox from '../../../../components/TextBox'
import Button from '../../../../components/Button'
import Tooltip from '../../../../components/Tooltip'
import ButtonList from '../../../../components/ButtonList'
import { useFetch } from '../../../../components/useFetch'
import { useMessageToast } from '../../../../components/MessageProvider'
import { useAuthState } from '../../../../components/AuthProvider'
import Spinner from '../../../../components/Loader/Spinner'
import errorMessageHandler from '../../errorMessageHandler'
import Icon from '../../../../components/Icon'
import styles from './AddSystemNotification.module.scss'

const AddSystemNotification = () => {
  const moment = require('moment-timezone')
  const clientConfigApiDomain = process.env.REACT_APP_BACKEND_BASE_URL
  const systemNotificationApiUrl = `${clientConfigApiDomain}/admin/systemnotifications`

  const { id } = useParams()

  const history = useNavigate()
  const { user } = useAuthState()
  const toast = useMessageToast()

  const [
    {
      data: addEditUserData,
      hasLoaded: addEditHasLoaded,
      hasError: addEditHasError,
      error,
    },
    addEditFetch,
  ] = useFetch()

  const [systemNotificationData, setSystemNotificationData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [hasLoaded, setHasLoaded] = useState(false)

  const fetchData = useCallback(async () => {
    setIsLoading(true)
    const response = await fetch(`${systemNotificationApiUrl}/${id}/`)
    const data = await response.json()
    setSystemNotificationData(data)
    setIsLoading(false)
    setHasLoaded(true)
  }, [id, systemNotificationApiUrl])

  useEffect(() => {
    if (!hasLoaded && id !== undefined) {
      fetchData()
    } else {
      // this enables the add screen to show up
      setHasLoaded(true)
    }
  }, [id, setIsLoading, hasLoaded, fetchData])

  useEffect(() => {
    if (id === undefined && addEditHasLoaded) {
      if (addEditHasLoaded) {
        toast.success(`System Notification added successfully.`)

        //history(`/adminpage/systemnotifications/${addEditUserData.id}`)
        history(`/admin/systemnotifications`)
      } else if (addEditHasError) {
        if (error.response.status === 400) {
          const errorMsgArray = errorMessageHandler(error.response.data)
          errorMsgArray.forEach((msg) => toast.error(msg))
        } else toast.error('Failed to add System Notification')
      }
    } else {
      if (addEditHasLoaded) {
        toast.success(`System Notification updated successfully.`)

        history(`/admin/systemnotifications/${addEditUserData.id}`)
      } else if (addEditHasError) {
        if (error.response.status === 400) {
          const errorMsgArray = errorMessageHandler(error.response.data)
          errorMsgArray.forEach((msg) => toast.error(msg))
        } else toast.error('Failed to update System Notification')
      }
    }
  }, [
    id,
    addEditUserData,
    addEditHasLoaded,
    addEditHasError,
    toast,
    history,
    error,
  ])

  let loadedBreadcrumbs = ''
  if (isLoading) {
    loadedBreadcrumbs = <Spinner />
  } else if (hasLoaded) {
    loadedBreadcrumbs = (
      <Breadcrumbs>
        <Breadcrumbs.Item render={() => <Link to="/">Home</Link>} />
        <Breadcrumbs.Item
          render={() => <Link to="/admin">Administration</Link>}
        />
        <Breadcrumbs.Item
          render={() => (
            <Link to="/admin/systemnotifications">System Notifications</Link>
          )}
        />
        {id ? (
          <>
            <Breadcrumbs.Item
              render={() => (
                <Link to={`/admin/systemnotifications/${id}`}>
                  System Notifications: {systemNotificationData.id}
                </Link>
              )}
            />
            <Breadcrumbs.Item last render={() => 'Edit'}></Breadcrumbs.Item>
          </>
        ) : (
          <Breadcrumbs.Item
            last
            render={() => 'Add System Notification'}
          ></Breadcrumbs.Item>
        )}
      </Breadcrumbs>
    )
  }

  return (
    // <Layout>
    //   <Content>
        <div className={styles.base}>
          <div className={styles.header}>
            {/* {loadedBreadcrumbs} */}
            <div className={styles.content}>
              {id ? (
                <span>System Notification: {systemNotificationData.id}</span>
              ) : (
               <div style={{display:'flex'}}>
                 
                  <span>Add System Notification</span>
                  <button
                noValidate
                style={{ marginLeft: '38%',cursor:'pointer' }}
                onClick={() => {
                  history(`/admin/systemnotifications`)
                }}
              >
                <Icon type="remove" size={'1.5rem'} />
              </button>

                  </div>
              )}
              {/* <p>System Notification Details</p> */}
            </div>
          </div>
          <div className={styles.body}>
            <Flex gutter={3}>
              {isLoading ? (
                <Spinner />
              ) : hasLoaded ? (
                <Flex.Item span={12}>
                  <Content></Content>
                  <div className={styles.base}>
                    <div className={styles.details}>
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          id: systemNotificationData.id,
                          message: systemNotificationData.message,
                          startDate: systemNotificationData.startDate
                            ? moment(systemNotificationData.startDate).format(
                                'YYYY-MM-DD'
                              )
                            : moment().toDate(),
                          endDate: systemNotificationData.endDate
                            ? moment(systemNotificationData.endDate).format(
                                'YYYY-MM-DD'
                              )
                            : moment().add(1, 'day').toDate(),
                        }}
                        validationSchema={Yup.object().shape({
                          message: Yup.string()
                            .trim()
                            .required('Message cannot be empty')
                            .max(
                              350,
                              'Message cannot be more than 350 characters long.'
                            ),
                          startDate: Yup.date()
                            .required('Start Date is required.')
                            .max(
                              Yup.ref('endDate'),
                              'Start Date should be before End Date.'
                            )
                            .typeError('Invalid Start date'),
                          endDate: Yup.date()
                            .required('End Date is required.')
                            .min(new Date(), 'End Date should be after today.')
                            .typeError('Invalid End date'),
                        })}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                          const data = {
                            Message: values.message,
                            startDate: moment(values.startDate).format(
                              'YYYY-MM-DD'
                            ),
                            endDate: moment(values.endDate).format(
                              'YYYY-MM-DD'
                            ),
                          }
                          let method = ''
                          let url = ''

                          if (id) {
                            data['Id'] = id
                            method = 'put'
                            url = `${systemNotificationApiUrl}/${id}/`
                          } else {
                            // data['created_by'] = user.username
                            method = 'post'
                            url = `${systemNotificationApiUrl}/`
                          }

                          addEditFetch({
                            method,
                            url,
                            data,
                          })

                          setSubmitting(false)
                        }}
                      >
                        {(props) => {
                          const {
                            values,
                            touched,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                          } = props

                          return (
                            <form onSubmit={handleSubmit} autoComplete="off">
                              <div>
                                {id ? (
                                  <>
                                    <h4>System Notification ID</h4>
                                    <span>{systemNotificationData.id}</span>
                                  </>
                                ) : null}
                              </div>
                              <div>
                                <TextBox
                                  errorText={
                                    touched.message ? errors.message : null
                                  }
                                  id="message"
                                  label={
                                    <Label
                                      error={touched.message && errors.message}
                                      htmlFor="message"
                                      required
                                    >
                                      <b>Message</b>
                                    </Label>
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  required
                                  value={values.message}
                                  width="100%"
                                  height="100%"
                                />
                              </div>
                              <div>
                                <Label
                                  error={touched.startDate && errors.startDate}
                                  htmlFor="startDate"
                                  required
                                >
                                  Start Date
                                  <Tooltip>
                                    <Content>
                                      <p>Start Date for System Notification.</p>
                                    </Content>
                                  </Tooltip>
                                </Label>
                                <DatePicker
                                  style={{ backgroundColor: 'yellow' }}
                                  selected={values.startDate}
                                  dateFormat="MM/dd/yyyy"
                                  name="startDate"
                                  minDate={moment().toDate()}
                                  placeholderText="Select a date"
                                  onChange={(date) =>
                                    setFieldValue('startDate', date)
                                  }
                                  scrollableYearDropdown
                                />
                                {!!errors.startDate && touched.startDate && (
                                  <div
                                    style={{ color: 'red', marginTop: '.5rem' }}
                                  >
                                    {errors.startDate}
                                  </div>
                                )}
                              </div>
                              <div>
                                <Label
                                  error={touched.endDate && errors.endDate}
                                  htmlFor="endDate"
                                  required
                                >
                                  End Date
                                  <Tooltip>
                                    <Content>
                                      <p>End Date for System Notification.</p>
                                    </Content>
                                  </Tooltip>
                                </Label>
                                <DatePicker
                                  style={{ backgroundColor: 'yellow' }}
                                  selected={values.endDate}
                                  dateFormat="MM/dd/yyyy"
                                  name="endDate"
                                  minDate={
                                    values.startDate
                                      ? moment(values.startDate).toDate()
                                      : moment().toDate()
                                  }
                                  placeholderText="Select a date"
                                  onChange={(date) =>
                                    setFieldValue('endDate', date)
                                  }
                                  scrollableYearDropdown
                                />
                                {!!errors.endDate && touched.endDate && (
                                  <div
                                    style={{ color: 'red', marginTop: '.5rem' }}
                                  >
                                    {errors.endDate}
                                  </div>
                                )}
                              </div>
                              {id ? (
                                <>
                                  <div>
                                    <h4>Created By</h4>
                                    <span>
                                      {systemNotificationData.createdBy}
                                    </span>
                                  </div>
                                  <div>
                                    <h4>Date Created</h4>
                                    <span>
                                      {moment(systemNotificationData.createdAt)
                                        .tz(user.preferences.timeZone)
                                        .format('L, LTS z')}
                                    </span>
                                  </div>
                                  <div>
                                    <h4>Edited By</h4>
                                    <span>
                                      {systemNotificationData.updatedAy}
                                    </span>
                                  </div>
                                  <div>
                                    <h4>Date Updated</h4>
                                    <span>
                                      {moment(systemNotificationData.updatedAt)
                                        .tz(user.preferences.timeZone)
                                        .format('L, LTS z')}
                                    </span>
                                  </div>
                                </>
                              ) : null}
                              <ButtonList>
                                <Button type="submit">
                                  {id ? `Save Changes` : `Save`}
                                </Button>
                                <Button
                                  onClick={() => {
                                    history('/admin/systemnotifications')
                                  }}
                                >
                                  Cancel
                                </Button>
                              </ButtonList>
                            </form>
                          )
                        }}
                      </Formik>
                    </div>
                  </div>
                </Flex.Item>
              ) : null}
            </Flex>
          </div>
        </div>
    //   </Content>
    // </Layout>
  )
}

export default AddSystemNotification
