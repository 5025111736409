import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useGeoState } from '../../../../components/GeoProvider'
import LinkButton from '../../../../components/LinkButton'
import statesMap from '../../../../utils/stateMap'
import modelOptions from './fluModelOptions'

import styles from './CbsaStats.module.scss'
import statStyles from './CbsaStat.module.scss'
import Tooltip from '../../../../components/Tooltip'
import Content from '../../../../components/Content'

const revisedText = `
Actual (Ground Truth) Stable value of Flu2. Claims data take few weeks to stabilize or be represented as ground truth.
  `
const unrevisedText = `
Actual (Ground Truth) Unstable value of Flu2. Initial value of claims data.
  `

  const StateStat = ({ state, mapOptions }) => {
    const history = useNavigate()
    return (
      <div className={statStyles.base}>
        <div
          style={{
            display: 'inline-flex',
            width: '48%',
            whiteSpace: 'nowrap',
            justifyContent: 'flex-start',
            overflow: 'hidden',
            color: '#424242',
            fontFamily: 'graphik',
            fontSize: '18px',
          }}
          title={state.name}
        >
          <LinkButton
            className={statStyles.btn}
            onClick={() => {
              history(`/about/model#state-${state.identifier}`)
            }}
            style={{ fontSize: '18px', color: '#424242', fontFamily: 'graphik' }}
            title={state.name}
          >
            {state.name.length > 15
              ? state.name.substring(0, 15) + '...'
              : state.name}
          </LinkButton>
        </div>
        <div
          style={{
            display: 'inline-flex',
            width: '25%',
            justifyContent: 'center',
            color: '#424242',
            fontFamily: 'graphik',
            fontSize: '14px',
          }}
        >
          <span>
            {state.revised_accuracy.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}
            %
          </span>
        </div>
        <div
          style={{
            display: 'inline-flex',
            width: '25%',
            justifyContent: 'center',
            color: '#424242',
            fontFamily: 'graphik',
            fontSize: '14px',
            paddingLeft: '5px',
          }}
        >
          <span>
            {state.unrevised_accuracy.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}
            %
          </span>
        </div>
      </div>
    )
  }
  
  const StateStats = ({ stateData, mapOptions }) => {
    const { displayDate, searchFilter, setSearchFilter } = useGeoState()
    const [btnSearchLocation, setBtnSearchLocation] = React.useState(false)
    const states = statesMap()
    const stateDataForDisplayDate = Object.entries(
      stateData[modelOptions[0].value]
    )
    // const mappedStateData = stateDataForDisplayDate.map((state) => {
    //   const stateName = states[state[0]]
    //   return { ...state[1], name: stateName, identifier: state[0] }
    // })
  
    const mappedStateData = stateDataForDisplayDate.map((state) => {
      return {
        identifier: state[1].OVERALL.geo_id,
        name: state[1].OVERALL.geo_name,
        ...state[1].OVERALL,
      }
    })
  
    const handleChange = (event) => {
      setSearchFilter(event.target.value)
    }
    function showSearch() {
      btnSearchLocation ? setBtnSearchLocation(false) : setBtnSearchLocation(true)
    }
    let filteredStateData = mappedStateData
    if (searchFilter !== '') {
      // eslint-disable-next-line array-callback-return
      filteredStateData = mappedStateData.filter((cbsa) => {
        if (
          cbsa.name &&
          cbsa.name.toLowerCase().includes(searchFilter.toLowerCase())
        ) {
          return cbsa
        }
      })
    }
    // eslint-disable-next-line array-callback-return
    filteredStateData = filteredStateData.filter((cbsa) => {
      if (cbsa.name && cbsa.name.length > 1) {
        return cbsa
      }
    })
  
    return (
      <div className={styles.base}>
        <div className={styles.header}>
          <div
            style={{
              width: '100%', //'95%',
              justifyContent: 'flex-start',
              padding: '10px 0px',
            }}
          >
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  display: 'inline-flex',
                  width: '70%',
                }}
              >
                <span>
                  <h5
                    style={{
                      fontFamily: 'graphik',
                      fontSize: '21px',
                      fontWeight: '600',
                    }}
                  >
                    States
                  </h5>
                </span>
              </div>
              <div
                style={{
                  display: 'inline-flex',
                  width: '30%',
                  justifyContent: 'right',
                }}
              >
                <span onClick={showSearch}>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11.3584 10.8862L18 17.01L17.0701 18L10.3724 11.7859C9.26916 12.7439 8.21615 13.0355 6.64841 13.0355C3.10647 13.0355 0.203247 10.1323 0.203247 6.59032C0.203247 3.04838 3.10647 0.145157 6.64841 0.145157C10.1903 0.145157 13.0936 3.04838 13.0936 6.59032C13.0936 8.36129 12.5197 9.7249 11.3584 10.8862ZM6.78389 1.45483C4.03637 1.45483 1.78389 3.70175 1.78389 6.44249C1.78389 9.20792 4.03637 11.4548 6.78389 11.4548C9.53142 11.4548 11.7839 9.18323 11.7839 6.44249C11.7839 3.70175 9.53142 1.45483 6.78389 1.45483Z"
                      fill="#E40046"
                    />
                  </svg>
                </span>
              </div>
            </div>
  
            {btnSearchLocation ? (
              <form>
                <input
                  type="text"
                  onChange={handleChange}
                  placeholder="Filter States"
                />
              </form>
            ) : // <h1>Yes</h1>
            null}
          </div>
          <div
            style={{
              padding: '0rem 0rem',
              paddingBottom: '0.5rem',
              paddingTop: '0.5rem',
              borderTop: '1px solid #424242',
              borderBottom: '1px solid #424242',
              marginTop: '5px',
            }}
          >
            <div
              style={{ display: 'inline-flex', width: '44%', padding: 'left' }}
            >
              <h5
                style={{
                  fontFamily: 'graphik',
                  fontSize: '14px',
                  justifyContent: 'flex-start',
                  fontWeight: '600',
                  color: '#424242;',
                }}
              >
                Area Name
              </h5>
            </div>
            <div
              style={{
                display: 'inline-flex',
                width: '25%',
                justifyContent: 'left',
                fontFamily: 'graphik',
              }}
            >
              <h5
                style={{
                  fontFamily: 'graphik',
                  fontWeight: '600',
                  fontSize: '14px',
                  color: '#424242;',
                  marginRight: '5px',
                }}
              >
                Revised
              </h5>
              <Tooltip placement="top" iconHeight="15" iconWidth="15">
                <Content maxWidth="300px" fontSize="12px">
                  <p>{revisedText}</p>
                </Content>
              </Tooltip>
            </div>
            <div
              style={{
                display: 'inline-flex',
                width: '25%',
                justifyContent: 'left',
                fontFamily: 'graphik',
                paddingLeft: '-10px',
              }}
            >
              <h5
                style={{
                  fontFamily: 'graphik',
                  fontSize: '14px',
                  fontWeight: '600',
                  color: '#424242;',
                  marginRight: '5px',
                }}
              >
                UnRevised
              </h5>{' '}
              <Tooltip placement="top" iconHeight="15" iconWidth="15">
                <Content maxWidth="300px" fontSize="12px">
                  <p>{unrevisedText}</p>
                </Content>
              </Tooltip>
            </div>
  
            {/* <div style={{ display: 'inline-flex', width: '10%' }}>
              <h5>Trend</h5>
            </div> */}
          </div>
        </div>
  
        <div style={{ overflowX: 'hidden', height: '100%' }}>
          {filteredStateData
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((state) => {
              return (
                <StateStat
                  state={state}
                  key={state.identifier}
                  mapOptions={mapOptions}
                />
              )
            })}
        </div>
      </div>
    )
  }

export default StateStats
