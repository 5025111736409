import React from 'react'
import { useNavigate } from 'react-router-dom'
//import usMsaAll from '../../../../components/Map/cb_2018_us_cbsa_20m_wkid102004.json1'
import { useGeoState } from '../../../../components/GeoProvider'
import LinkButton from '../../../../components/LinkButton'
import Tag from '../../../../components/Tag'
import statesMap from '../../../../utils/stateMap'

import styles from './CbsaStats.module.scss'
import statStyles from './CbsaStat.module.scss'

import Tooltip from '../../../../components/Tooltip'
import Content from '../../../../components/Content'

const CbsaStat = ({ cbsaJson, cbsa, mapOptions }) => {
  const history = useNavigate()
  const states = statesMap()

  return (
    <div className={statStyles.base}>
      <div
        style={{
          // display: 'inline-flex',
          width: '55%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
        title={cbsa.name}
      >
        <LinkButton
          className={statStyles.btn}
          onClick={() => {
            history(`/forecasts/Flumap#metro-${cbsa.identifier}`)
          }}
          title={cbsa.name}
        >
          {cbsa.name.length > 10
            ? cbsa.name.substring(0, 10) + '...'
            : cbsa.name}
        </LinkButton>
        <div
          style={{
            textTransform: 'uppercase',
            opacity: '0.6',
            fontSize: '0.8rem',
            position: 'relative',
            top: '1.1rem',
            left: '5px',
          }}
        >
          {states[cbsa.state]}
        </div>
        <div
          style={{
            fontSize: '10px',
          }}
        >
          Accuracy :{' '}
          <b>
            {cbsa.forecast_ci_revised_95.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            }) === 0
              ? 'NA'
              : cbsa.forecast_ci_revised_95.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                }) + '%'}
          </b>
        </div>

        {/* <span>
          {cbsa.forecast_ci_revised_95.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          }) == 0
            ? 'NA'
            : cbsa.forecast_ci_revised_95.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              }) + '%'}
        </span> */}
      </div>
      <div
        style={{
          marginTop: '-45px',
          height: '10%',
          marginLeft: '160px',
          textAlign: 'center',
          // display: 'inline-flex',
          // width: '45%',
          // justifyContent: 'flex-middle',
        }}
      >
        <Tag
          dataValue={cbsa}
          levelKey={mapOptions.tagKey}
          valueKey={mapOptions.tagValue}
          showNumbers={true}
        />
        {/* <RiskTrendIcon trendingPercentage={cbsa.trending_percentage} /> */}
      </div>

      {/* <div
        style={{
          display: 'inline-flex',
          width: '32%',
          justifyContent: 'flex-end',
        }}
      >
        <span>
          {cbsa.forecast_ci_revised_95.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          }) == 0
            ? 'NA'
            : cbsa.forecast_ci_revised_95.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              }) + '%'}
        </span>
      </div> */}
    </div>
  )
}

const CbsaStats = ({cbsaJson,  cbsaData, mapOptions }) => {  
  const { displayDate, searchFilter, setSearchFilter } = useGeoState()

  const cbsaDataForDisplayDate = Object.entries(cbsaData[displayDate])
  const aboutText =
    'The Prediction Interval Accuracy is defined in terms of how often the forecasted prediction interval contains the true Actual value (Last Flu Season).'

  const inputRef = React.useRef(false)
  let isCancelled = React.useRef(false)
  const [btnSearchLocation, setBtnSearchLocation] = React.useState(false)
  let accuracy = 0
  const mappedCbsaData = cbsaDataForDisplayDate.map((cbsa) => {
    const filteredCbsa = cbsaJson.features.filter((c) => {
      return c.properties.cbsa.toString() === cbsa[0].toString()
    })

    // This will only take the name out from the name followed by state
    // Example: Minneapolis-St. Paul-Bloomington, MN-WI => Minneapolis-St. Paul-Bloomington

    // if (cbsa[1].forecast_ci_revised_95_overall !== 0) {
    //   accuracy = cbsa[1].forecast_ci_revised_95_overall
    // }

    const cityName =
      filteredCbsa[0] && filteredCbsa[0].properties.name.split(',')[0]
    return { ...cbsa[1], name: cityName, identifier: cbsa[0] }
  })
  const handleChange = (event) => {
    setSearchFilter(event.target.value)
  }
  
  if (mappedCbsaData[1].forecast_ci_revised_95_overall !== 0) {
    accuracy = mappedCbsaData[1].forecast_ci_revised_95_overall
  }
  let filteredCbsaData = mappedCbsaData
  if (searchFilter !== '') {
    // eslint-disable-next-line array-callback-return
    filteredCbsaData = mappedCbsaData.filter((cbsa) => {
      if (
        cbsa.name &&
        cbsa.name.toLowerCase().includes(searchFilter.toLowerCase())
      ) {
        return cbsa
      }
    })
  }
  // eslint-disable-next-line array-callback-return
  filteredCbsaData = filteredCbsaData.filter((cbsa) => {
    if (cbsa.name && cbsa.name.length > 1) {
      return cbsa
    }
  })
  function showSearch() {
   
    isCancelled.current = true
    btnSearchLocation ? setBtnSearchLocation(false) : setBtnSearchLocation(true)
    btnSearchLocation ? setSearchFilter('') : (inputRef.current = false)
  }
  return (
    <div className={styles.base}>
      <div className={styles.header}>
        <div
          style={{
            width: '100%', //'95%',
            justifyContent: 'flex-start',
          }}
        >
          <div style={{ display: 'flex' }}>
            <div
              style={{
                display: 'inline-flex',
                width: '70%',
              }}
            >
              <span>
                <h5
                  style={{
                    fontFamily: 'graphik',
                    fontSize: '21px',
                    fontWeight: '600',
                  }}
                >
                  Metro Areas
                </h5>
              </span>
            </div>
            <div
              style={{
                display: 'inline-flex',
                width: '30%',
                justifyContent: 'right',
              }}
            >
              <span onClick={showSearch}>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.3584 10.8862L18 17.01L17.0701 18L10.3724 11.7859C9.26916 12.7439 8.21615 13.0355 6.64841 13.0355C3.10647 13.0355 0.203247 10.1323 0.203247 6.59032C0.203247 3.04838 3.10647 0.145157 6.64841 0.145157C10.1903 0.145157 13.0936 3.04838 13.0936 6.59032C13.0936 8.36129 12.5197 9.7249 11.3584 10.8862ZM6.78389 1.45483C4.03637 1.45483 1.78389 3.70175 1.78389 6.44249C1.78389 9.20792 4.03637 11.4548 6.78389 11.4548C9.53142 11.4548 11.7839 9.18323 11.7839 6.44249C11.7839 3.70175 9.53142 1.45483 6.78389 1.45483Z"
                    fill="#E40046"
                  />
                </svg>
              </span>
            </div>
          </div>
          <div
            style={{
              fontSize: '12px',
            }}
          >
            All MSAs Average Accuracy :{' '}
            <b>
              {accuracy.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              }) === 0
                ? 'NA'
                : accuracy.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  }) + '%'}
            </b>
            <span style={{ marginLeft: '5px' }}>
              {' '}
              <Tooltip placement="top" iconHeight="15" iconWidth="15">
                <Content maxWidth="300px" fontSize="12px">
                  <p>{aboutText}</p>
                </Content>
              </Tooltip>
            </span>
          </div>
          {btnSearchLocation ? (
            <input
              ref={inputRef}
              type="text"
              onChange={handleChange}
              placeholder="Filter Metro Areas"
            />
          ) : null}
        </div>
        <div
          style={{
            padding: '0rem 0rem',
            paddingBottom: '0.5rem',
            paddingTop: '0.5rem',
            borderTop: '1px solid #424242',
            borderBottom: '1px solid #424242',
            marginTop: '5px',
          }}
        >
          <div style={{ display: 'inline-flex', width: '55%' }}>
            <h4>Area Name</h4>
          </div>
          <div
            style={{
              display: 'inline-flex',
              width: '25%',
              justifyContent: 'flex-middle',
            }}
          >
            <h4>Influenza Levels</h4>
          </div>
          {/* <div
            style={{
              display: 'inline-flex',
              width: '38%',
              justifyContent: 'flex-end',
            }}
          >
            <h4>CI Accuracy</h4>
          </div> */}
        </div>
      </div>
      <div style={{ overflow: 'scroll', height: '100%' }}>
        {filteredCbsaData
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((cbsa) => {
            return (
              <CbsaStat
                cbsa={cbsa}
                key={cbsa.identifier}
                mapOptions={mapOptions}
                cbsaJson={cbsaJson}
              />
            )
          })}
      </div>
    </div>
  )
}

export default CbsaStats
