import React from 'react'

import Spinner from '../Loader/Spinner'
import BarChartDisplay from './ColumnChartDisplay'

const ColumnChart = ({data, chartName,renderType }) => {
  return data ? (
    <BarChartDisplay data={chartName==="genderAgechartSurv"?JSON.parse(data[0][1].json_value):data} chartName={chartName} renderType={renderType} />
  ) : (
    <Spinner />
  )
}

export default ColumnChart
