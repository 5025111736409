import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import DataTable, { DropDownListFilter } from '../../../components/DataTableNew'
import Spinner from '../../../components/Loader/Spinner'
import ButtonList from '../../../components/ButtonList'
import LinkButton from '../../../components/LinkButton'
import Modal from '../../../components/ModalPopupProfile'
import Content from '../../../components/Content'
import Layout from '../../../components/Layout'
import Button from '../../../components/Button'
import Flex from '../../../components/Flex'
import geographyHandler from '../../../utils/geographyHandler'
import handleResponse from '../../../utils/handleFetchResponse'
import { useMessageToast } from '../../../components/MessageProvider'
import dateSortHandler from '../../../utils/dateSorterDatatable'
import ModelAddSubs from '../../../components/ModalPopupAddSubs'
import geoTypeDisplay from '../../../utils/geoTypeDisplay'
import Icon from '../../../components/Icon'
import styles from './Subscription.module.scss'
import { useAuthState } from '../../../components/AuthProvider'
import activityLog from '../../../utils/activityLog'
import AddSubscriptionForm from './AddEditSubscription/index'
import { useGeoState } from '../../../components/GeoProvider'
import AdminHeader from '../../AdminPages/AdminHeader'
import add from '../../../assets/images/Add.png';

const Subscriptions = () => {
  const { user } = useAuthState()
  const history = useNavigate()
  const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL
  const subscriptionsUrl = `${baseUrl}/admin/subscriptions`
  const toast = useMessageToast()
  const [loading, setLoading] = useState({
    isLoading: false,
    hasLoaded: false,
    hasError: false,
  })
  const [subscriptions, setSubscriptions] = useState([])
  const [showUnsubscribeDialog, setShowUnsubscribeDialog] = useState(false)
  const [selectedSubscription, setSelectedSubscription] = useState({})
  const { showAddsubscribePop, setShowAddsubscribePop } = useGeoState()
  const [subCount, setsubCount] = useState(0)

  const [getCbsaJsonFile, setCbsaJsonFile] = useState();

  useEffect(() => {
            let url = `${window.location.origin}/cb_2018_us_cbsa_20m_wkid102004.json`
            fetch(url)
              .then(function (res) {       
                return res.json();
              })
              .then(function (data) {
               setCbsaJsonFile(data);
              })
              .catch(function (err) {
                console.log(err, " error file load cb_2018_us_cbsa_20m_wkid102004 ");
              });
          }, []);

  const handleDeleteSuccess = () => {
    setLoading((loading) => ({
      ...loading,
      isLoading: false,
      hasLoaded: true,
    }))
    setShowUnsubscribeDialog(false)
    toast.success(`Subscription deleted successfully`)
    setSubscriptions(subscriptions.filter((u) => u !== selectedSubscription))
    if (subscriptions !== null && subscriptions !== undefined) {
      setsubCount(subscriptions.length - 1)
    }
  }

  const handleDeleteError = (err) => {
    setLoading({
      hasError: true,
      isLoading: false,
      hasLoaded: false,
    })
    window.newrelic.noticeError(
      `Error while unsubscribing admin/subscriptions`,
      err
    )
    toast.error(`Error when attempting to delete subscription`)
  }
  const deleteSubscriptionHandler = () => {
    setLoading((loading) => ({ ...loading, isLoading: true }))
    fetch(`${subscriptionsUrl}/${selectedSubscription.subscription_id}`, {
      method: 'Delete',
    })
      .then((response) => {
        if (!response.ok) throw Error('Failed to delete user')
        handleDeleteSuccess()
      })
      .catch((error) => {
        handleDeleteError(error)
      })
    setShowUnsubscribeDialog(false)
  }

  const createSubscriptionsData = useCallback((data,cbsaJsonData) => {
    
    const tableRows = []
    for (const key in data) {
      const obj = data[key]
      const subscriptions = data[key].subscriptions
      for (let subscription of subscriptions) {
        tableRows.push({
          ...subscription,
          user_name: key,
          full_name: obj.last_name + ', ' + obj.first_name,
          start_date: subscription.start_date.split('T')[0],
          end_date: subscription.end_date.split('T')[0],
          geography: geographyHandler(
            subscription.geo_type,
            subscription.geo_id, 
            cbsaJsonData
          ),
          displayGeoType: geoTypeDisplay(subscription.geo_type),
        })
      }
    }
    setSubscriptions(tableRows)
  }, [])

  const getListOfSubscriptions = useCallback((cbsaJsonData) => {
    //setLoading((loading) => ({ ...loading, isLoading: true }))
    fetch(`${subscriptionsUrl}`)
      .then((res) => handleResponse(res))
      .then((response) => {
        // setLoading((loading) => ({
        //   ...loading,
        //   isLoading: false,
        //   hasLoaded: true,
        // }))
        
        createSubscriptionsData(response,cbsaJsonData)
        //For getting count  
        
        var cnt=0
        if (response !== undefined && response !== null) {
          for (const key in response) {
            const obj = response[key]
            cnt = cnt + obj.subscriptions.length
            setsubCount(cnt)
          }
        }

      })
      .catch((err) => {
        setLoading((loading) => ({
          ...loading,
          hasError: true,
          isLoading: false,
        }))
      })
  }, [subscriptionsUrl, createSubscriptionsData])

  const Unsubscribe = ({
    cell: {
      row: { original: subscription },
    },
  }) => {
    const onUnsubscribeClick = () => {
      setSelectedSubscription(subscription)
      setShowUnsubscribeDialog(true)
    }
    return (
      <ButtonList className={styles.icons}>
        <LinkButton onClick={onUnsubscribeClick}>
          <svg
            width="22"
            height="12"
            viewBox="0 0 22 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.09 1.41L14.67 5H0.5V7H14.67L11.08 10.59L12.5 12L18.5 6L12.5 0L11.09 1.41ZM19.5 0V12H21.5V0H19.5Z"
              fill="white"
            />
          </svg>
        </LinkButton>

      </ButtonList>
    )
  }

  const columns = [
    { Header: 'User Name', accessor: 'user_name', defaultCanSort: true },
    { Header: 'Name', accessor: 'full_name' },
    {
      Header: 'Location Type',
      accessor: 'displayGeoType',
      Filter: DropDownListFilter,
    },
    { Header: 'Location Name', accessor: 'geography' },
    {
      Header: 'Start Date',
      accessor: 'start_date',
      sortMethod: dateSortHandler,
    },
    {
      Header: 'End Date',
      accessor: 'end_date',
      sortMethod: dateSortHandler,
    },
    {
      Header: 'Infection Type',
      accessor: 'infection_type',
      Filter: DropDownListFilter,
    },
    {
      Header: 'Notification Type',
      accessor: 'notification_type',
      Filter: DropDownListFilter,
    },
    { Header: 'Notification To', accessor: 'notification_to' },
    {
      Header: '',
      accessor: 'actions',
      disableFilters: true,
      disableSortBy: true,
      Cell: Unsubscribe,
    },
  ]

  const onUnsubscribeCancelClick = () => {
    setShowUnsubscribeDialog(false)
    setSelectedSubscription({})
  }

  const onAddsubscribeClick = () => {
    setShowAddsubscribePop(true)

  }

  useEffect(() => {
    if (getCbsaJsonFile !== undefined)
    {
      getListOfSubscriptions(getCbsaJsonFile)
      var url = window.location.href
    var name = ''
    if (url.indexOf('subscriptions') !== -1) name = 'Subscriptions'

    var logObject = {
      dashboard_url: url,
      dashboard_name: name,
      indicator_name: name,
      geo_name: '',
      geo_type: '',
      user_name: user.username,
      email: user.email,
    }
    activityLog(logObject)
    }    
    
  }, [getCbsaJsonFile])

  return (
    <Layout>
      <Content>
        <div className={styles.base}>

          <div className={styles.body}>
            <AdminHeader />
            <div>
              <span className={styles.headerText}>{subCount} &nbsp;&nbsp;Subscriptions</span>
              <p className={styles.headerSubText}>
                All active user subscriptions are listed here, Emails and SMS
                will be sent out ever Monday.
              </p>
            </div>
            
               
                  <ButtonList spacing={2} style={{ marginLeft: '65rem', border:'1px solid black', padding:'0.75rem' }}>
                    <LinkButton 
                      //icon="add"
                      // onClick={() => {
                      //   history('/admin/subscriptions/add')
                      // }}
                      onClick={onAddsubscribeClick}
                      style={{fontweight:'500', fontSize:'16px',color: '#323334'}}                      
                    >
                      <img id="open-filter" className={styles.add} src={add} /> Subscription
                    </LinkButton>
                  </ButtonList>                 
                  {
                    getCbsaJsonFile && subscriptions && (
                      <DataTable
                      data={subscriptions}
                      columns={columns}
                      hasPagination
                      hasFiltering
                      hasSorting
                    />
                    )
                  }
                             
           
          </div>
        </div>
      </Content>
      <ModelAddSubs
        open={showAddsubscribePop}
        onClose={() => setShowAddsubscribePop(false)}
      >
        <AddSubscriptionForm location={''} />
      </ModelAddSubs>
      <Modal
        open={showUnsubscribeDialog}
        onClose={() => setShowUnsubscribeDialog(false)}
      >
        <button
          noValidate
          style={{ marginLeft: '90%' }}
          onClick={onUnsubscribeCancelClick}
        >
          <Icon type="remove" size={'1.5rem'} />
        </button>
        <div
          className={styles.tdFormat}
          style={{ marginBottom: '0rem', fontSize: '18px' }}
        >
          Subscription
          {/* Are you sure you want to delete this subscription? */}
          <div className={styles.modal}>
            <div style={{ display: 'contents' }}>
              <div style={{ height: '20px' }} className={styles.thFormat}>
                Name{' '}
              </div>
              <div className={styles.delvalFormat}>
                {' '}
                {selectedSubscription.full_name}
              </div>
            </div>
            <div style={{ display: 'contents' }}>
              <div style={{ height: '20px' }} className={styles.thFormat}>
                Username
              </div>
              <div className={styles.delvalFormat}>
                {' '}
                {selectedSubscription.user_name}
              </div>
            </div>
            <div style={{ display: 'contents' }}>
              <div style={{ height: '20px' }} className={styles.thFormat}>
                Subscription Type
              </div>
              <div className={styles.delvalFormat}>
                {' '}
                {selectedSubscription.notification_type}
              </div>
            </div>
            <div style={{ display: 'contents' }}>
              <div style={{ height: '20px' }} className={styles.thFormat}>
                Email
              </div>
              <div className={styles.delvalFormat}>
                {' '}
                {selectedSubscription.notification_to}
              </div>
            </div>
            <div>
              <div
                style={{ height: '20px', width: '47%' }}
                className={styles.thFormat}
              >
                Start Date
              </div>
              <div
                style={{ height: '20px', width: '50%' }}
                className={styles.thFormat}
              >
                End Date
              </div>
            </div>
            <div>
              <div
                className={styles.tdFormat}
                style={{
                  paddingBottom: '1rem',
                  height: '20px',
                  width: '47%',
                }}
              >
                {' '}
                {selectedSubscription.start_date}
              </div>
              <div
                className={styles.tdFormat}
                style={{ paddingBottom: '1rem', height: '20px' }}
              >
                {' '}
                {selectedSubscription.end_date}
              </div>
            </div>
            <div style={{ display: 'contents' }}>
              <div style={{ height: '20px' }} className={styles.thFormat}>
                Infection Type
              </div>
              <div className={styles.delvalFormat}>
                {' '}
                {selectedSubscription.infection_type}
              </div>
            </div>
            <div style={{ display: 'contents' }}>
              <div
                style={{ display: 'contents' }}
                className={styles.thFormat}
              >
                Location Name
              </div>
              <div className={styles.delvalFormat}>
                {' '}
                {selectedSubscription.geography}
              </div>
            </div>
          </div>
        </div>
        <ButtonList>
          <Button onClick={onUnsubscribeCancelClick}>Close</Button>
          <Button primary type="submit" onClick={deleteSubscriptionHandler}>
            Unsubscribe
          </Button>
        </ButtonList>
      </Modal>
    </Layout>
  )
}

export default Subscriptions
