import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HighchartsMap from 'highcharts/modules/map'

import { useGeoState } from '../../../components/GeoProvider'
import { useGeoFetch } from '../../../components/GeoProvider'
//import usCountiesAll from '../../../components/Map/us_hrr_values.json1'
import dayjs from 'dayjs'

const CovidHRRMap = ({ hrrData, mapOptions, toolTipFormatter, hrrMapJson }) => {
  if (typeof Highcharts === 'object') {
    HighchartsMap(Highcharts)
  }
  
  const actualsDate = dayjs().day(-4).format('YYYY-MM-DD')
  const history = useNavigate()
  const { hrrDataWeekEndDate, dataSelected, setHrrEndDate  } = useGeoState()
  const hrrDataForDisplayDate = Object.entries(hrrData[hrrDataWeekEndDate])  // Object.entries(hrrData[hrrDataWeekEndDate])
  const mapHRRData = hrrDataForDisplayDate.map((county) => {
    return {
      value: county[1][mapOptions.value],
      code: county[0].toString(),
      identifier: county[0].toString(),
      ...county[1],
    }
  })
  let title = mapOptions.mapTitle

  if (title !== null || title !== undefined) {
    if (dataSelected === 'hrr') {
      title = 'Covid Accuracy for hopitalization'
    } else if (hrrDataWeekEndDate <= actualsDate) {
      title = title + ' - Actuals'
    } else {
      title = title + ' - Forecast'
    }
  }

  const graphOptions = {
    title: {
      text: title,
      style: {
        color: '#424242',
        fontWeight: '700',
        //fontfamily: 'graphik',
        fontsize: '16px',
      },
      align: 'center',
      marginLeft: 100,
    },
    subtitle: {
      text: mapOptions.mapSubTitle,
    },
    chart: {
      backgroundColor: 'none',
      displayErrors: false,
    },
    legend: mapOptions.legend,
    mapNavigation: {
      enableMouseWheelZoom: false,
      enableButtons: true,
      // enabled: true,
      buttonOptions: {
        verticalAlign: 'top',
        align: 'right',
      },
    },
    credits: {
      enabled: false,
    },
    colorAxis: mapOptions.countyColorAxis,
    tooltip: {
      useHTML: true,
      padding: 0,
      formatter: toolTipFormatter,
    },
    series: [
      {
        mapData: hrrMapJson,
        data: mapHRRData,
        joinBy: ['HRRNUM', 'geo_id'],
        borderColor: 'gray',
        borderWidth: 1,
        states: {
          select: {
            color: null,
            borderColor: 'gray',
          },
          hover: {
            borderColor: 'gray',
          },
        },
        shadow: false,
        cursor: 'pointer',
      },
    ],
    plotOptions: {
      series: {
        events: {
          click: function (eventarg) {
            history({ hash: `hrr-${eventarg.point.identifier}` })
          },
        },
      },
    },
    mapline: {
      showInLegend: true,
      enableMouseTracking: true,
    },
  }
  return (
    <HighchartsReact
      containerProps={{
        style: {
          height: '70vh',
          // width: '120vh',
          position: 'relative',
          display: 'flex',
          // marginLeft: '20vh',
        },
      }}
      constructorType="mapChart"
      highcharts={Highcharts}
      options={graphOptions}
      allowChartUpdate
      updateArgs={[true, true, false]}
    />
  )
}

export default CovidHRRMap
