import React, { useState, useEffect } from 'react'
import styles from './RiskPredictorPanel.module.scss'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
// import moment from 'moment'

const RiskPredictorPanel = (data, mapType) => {
  const history = useNavigate()
  const today = dayjs().day(-7).format('YYYY-MM-DD')
  return (
    <div className={styles.base}>
      <div className={styles.kpisDiv}>
        <div className={styles.heading}>
          <p>Clinical Risk Predictors</p>
        </div>
        <div className={styles.infoDiv1}>
          <div>
            <a href="" onClick={() => {
                      history(`/DiseasePrediction/RiskCalculator`)
                    }} className={styles.title}>
              Cardiac Risk Calculator
            </a>
            {/* <span>{moment(today).format('MMM DD, YYYY')}</span> */}
          </div>

          <p>
            The cardiac risk calculator uses machine learning to assess
            individual patient risk of severe cardiac conditions.
          </p>
        </div>
        <div className={styles.infoDiv2}>
          <div>
            <a href="#" className={styles.title}>
              Cancer Prediction{'    '}
            </a>
            {/* <span>{moment(today).format('MMM DD, YYYY')}</span> */}
          </div>

          <p>
            Predictive modelling to determine the likelihood of developing
            certain cancers and adverse cancer outcomes.
          </p>
        </div>
        <div className={styles.infoDiv3}>
          <div>
            <a
              onClick={() => {
                history('/DiseasePrediction/RareDisease')
              }}
              href=""
              className={styles.title}
            >
              Rare Diseases
            </a>
            {/* <span> March 24, 2022</span> */}
          </div>

          <p>
            Predictive modelling to identify undiagnosed Thalassemia patients.
          </p>
        </div>
      </div>
    </div>
  )
}
export default RiskPredictorPanel
