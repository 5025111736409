import React, { useState, useEffect, useCallback } from 'react'
import { useParams, Link, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { useGeoFetch } from '../../../../components/GeoProvider'
import Spinner from '../../../../components/Loader/Spinner'
// import Content from '../../../../components/Content'
// import Layout from '../../../../components/Layout'
import statesMap from '../../../../utils/stateMap'
import cbsasMap from '../../../../utils/cbsasMap'
import countyMap from '../../../../utils/countyMap'
// import FluAccuracyChart from './FluAccuracyChart'
// import FluSeasonsChart from './FluSeasonsChart'
import seasons from '../../../../utils/seasons.json'
import getEpiWeek from '../../../../utils/getEpiWeek'
import styles from './FluDetails.module.scss'
import { useAuthState } from '../../../../components/AuthProvider'
import activityLog from '../../../../utils/activityLog'
// import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import Content from '../../../../components/Content' 
import Layout from '../../../../components/Layout'
import BaseLine from '../../../../components/BaseLine' 
import Tooltip from '../../../../components/Tooltip' 
import ErrorMessage from '../../../../components/Layout/ErrorMessageNew' 
import Select from 'react-select' 
import FluAccuracyChart from './FluAccuracyChart'  
import FluSeasonsChart from './FluSeasonsChart' 
const longText = `
 `
export const FluDetails = () => {
 
  const { user } = useAuthState()
  const history = useNavigate()
  const { loc, area } = useParams()
  const seasonString = seasons.join(',')
  // state added if we put in a dropdown for later
  // eslint-disable-next-line no-unused-vars
  const [errorCode, setErrorCode] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [errorSubject, setErrorSubject] = useState()
  const [errorOccured, seterrorOccured] = useState(false)
  const [geoData, setGeoData] = useState(null)
  const [seasonsData, setSeasonsData] = useState(null)
  const lastepiWeek = getEpiWeek(moment().subtract(7, 'd')._d)
  const lastweekyear = moment().subtract(7, 'd').year()
  const [selectedOptions, setSelectedOptions] = useState([])
  const [getSeasoon, setSeasoon] = useState(seasonString)
  let displayName = ''
  let displayType = area
  let queryType = area

  switch (area) {
    case 'state':
      displayName = statesMap()[loc]
      break
    case 'metro':
    case 'city':
      queryType = 'city'
      displayType = 'Metro'
     // displayName = cbsasMap()[loc]
      break
    case 'county':
      //displayName = countyMap()[loc]
      break
    default:
    // code block
  }

 

  var logObject = {
    dashboard_url: window.location.href,
    dashboard_name: 'Influenza Details',
    indicator_name: 'Influenza Details',
    geo_name: loc,
    geo_type: queryType,
    user_name: user.username,
    email: user.email,
  }
  const getLatestSeasonsData = (data) => {    
    let filteredData = {}
    Object.entries(data).forEach(([week, weekData]) => {      
      if ([seasons[0]].includes(weekData.epi_season)) {
        filteredData[week] = weekData
      } else if (
        ([seasons[1]].includes(weekData.epi_season) &&
          weekData.epi_period_week > lastepiWeek) ||
        (weekData.epi_period_week < 20 &&
          weekData.epi_period_year == lastweekyear)
      ) {
        filteredData[week] = weekData
      }
    })
    return filteredData
  }

  const transformGeoData = (data) => {    
    if (data != null || data != undefined) {
      const latestSeason = getLatestSeasonsData(data)

      let transformedData = []
      Object.entries(latestSeason).forEach(function (dateObj) {
        const key = dateObj[0]
        const value = dateObj[1][loc]
        transformedData.push({ date: key, ...value })
      })

      setGeoData(transformedData)
    }
  }
  const { data: fetchData, error } = useGeoFetch(
    '',
    '',
    'flu',
    `${queryType}?seasons=${getSeasoon}&identifier=${loc}`
  )

  useEffect(
    () => {
      if (fetchData !== null && fetchData !== undefined) {
        if (fetchData.response_code === 200) {          
        setSeasonsData(fetchData.response_data)
        transformGeoData(fetchData.response_data)
      }
      else {
        setErrorCode(fetchData.response_code)
        setErrorMessage(fetchData.response_message)
        setErrorSubject(fetchData.response_subject)
        seterrorOccured(true)
      }
    }
    },
    [fetchData],
    getSeasoon
  )
  if ( errorOccured) {
    return (
      <ErrorMessage
        errorCode={errorCode}
        errorMessage={errorMessage}
        errorSubject={errorSubject}
        errorDetails={errorMessage}
      />
    )
  }
  const tabsChangeHandlerNew = (e) => {
   

    setSelectedOptions(e)
    //   if (e.length > 0) {
    //   setSeasoon(e[0].label)
    // }
  }
  const dataFluSeason = [
    {
      value: 11,
      label: '2023-24',
    },
    {
      value: 10,
      label: '2022-23',
    },
    {
      value: 9,
      label: '2021-22',
    },
    {
      value: 8,
      label: '2020-21',
    },
    {
      value: 7,
      label: '2019-20',
    },
    {
      value: 6,
      label: '2018-19',
    },
    {
      value: 5,
      label: '2017-18',
    },
    {
      value: 4,
      label: '2016-17',
    },
    {
      value: 3,
      label: '2015-16',
    },
    {
      value: 2,
      label: '2014-15',
    },
  ]
  const colourStyles = {
    control: (styles) => ({
      ...styles,  
      backgroundColor: 'white',
      borderRadius: '0px',
    }),
  }
  let accuracyValue = 0
  if (geoData !== undefined && geoData !== null) {    
    const natsDataForDisplayDate = Object.entries(
      geoData[Object.keys(geoData).pop()]
    )

    const mapNatsData = natsDataForDisplayDate.map((nat) => {
      if (nat[0] === 'forecast_ci_revised_95') {
        accuracyValue = nat[1] === 0 ? 'NA' : nat[1].toFixed(2) + '%'
        return {
          value: nat[1],
        }
      }
    })
  }

  return (
   
    <Layout>
       
      <Content>
        <div className={styles.base}>
          <>
            <div className={styles.header1}>
              <div className={styles.forecastAcc1}>
                <div className={styles.headingDiv}>
                  <h1>{displayName} Flu Details</h1>
                </div>
                <div className={styles.accDiv}>
                  <div>Accuracy</div>
                  <div>
                    <span style={{ fontSize: '15px' }}>{accuracyValue}</span>
                  </div>

                  <div style={{ display: 'flex' }}>
                    <Tooltip placement="bottom" iconHeight="15" iconWidth="15">
                      <Content maxWidth="300px" fontSize="10px">
                        <p>{longText}</p>
                      </Content>
                    </Tooltip>
                    <a
                      style={{
                        marginLeft: '10px',
                        fontSize: '12px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        history(`/about/model#accuracy`)
                      }}
                    >
                      {' '}
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.body}>
              <div className={styles.divMain}>
                <div className={styles.divddl}>
                  <div style={{ display: 'flex' }}>
                    <h4>Seasonal Flu Pattern</h4>{' '}
                    <div style={{ marginLeft: '12px' }}>
                      <BaseLine></BaseLine>
                    </div>
                  </div>
                  <span>This graph will compare 5 years at a time.</span>

                  <div
                    style={{
                      minWidth: '20%',
                      maxWidth: '40%',
                      marginLeft: '60%',
                      marginTop: '-40px',
                    }}
                  >
                    {/* <h4 >Select Season</h4> */}
                    <Select
                      isMulti
                      options={dataFluSeason}
                      onChange={tabsChangeHandlerNew}
                      value={selectedOptions}
                      isOptionDisabled={() => selectedOptions.length >= 5}
                      components={makeAnimated()}
                      placeholder={`Select season`}
                      styles={colourStyles}
                    />
                  </div>
                </div>
                {seasonsData !== undefined && seasonsData !== null ? (
                  <FluSeasonsChart
                    data={seasonsData}
                    identifier={loc}
                    selectedOptions={selectedOptions}
                  />
                ) : (
                  <Spinner />
                )}
              </div>
              {geoData !== undefined && geoData !== null ? (
                <FluAccuracyChart data={geoData} />
              ) : (
                <Spinner />
              )}
            </div>
          </>
        </div>
      </Content>
     
    </Layout>

 )
}

export default FluDetails
