import { requiresAuth } from '../components/AuthProvider'

import Home from './Home'
import Login from './Login'
import OneHealthcareIdLogin from './OneHealthcareIdLogin'
import ActiveDirectoryLogin from './ActiveDirectoryLogin'
import LoginRedirect from './LoginRedirect'
import ActiveDirectoryLoginRedirect from './ActiveDirectoryLoginRedirect'
import SignOut from './SignOut'
import ActiveDirectorySignOut from './ActiveDirectorySignOut'
import Profile from './Profile'
import AccessAbort from './AccessAbort'
import NoAccess from './NoAccess'
import NotFound from './NotFound'
import Raredisease from './RareDisease/RarediseaseDetails'
import Dashboard from './Dashboard'
import Vulnerability from './Vulnerability/index'
import FluTabs from './Flu/FluTabs'
import FluDetails from './Flu/FluTabs/FluDetails'
import FluSurveillance from './Surveillance/FluSurveillance'
import HRRMap from './Forecasts/HRR'
import ForecastDashboard from './Forecasts/Home'
import Feedback from './About/Feedback'
import AdminPages from './AdminPages'
import UsersPage from './AdminPages/Users'
import SystemNotificationsPage from './AdminPages/SystemNotifications'
import AddEditUser from './Admin/Users/AddEditUser'
import UserActivity from './Admin/UserActivity'
import Subscriptions from './Admin/Subscriptions/AddSubs'
import AddSubscription from './Admin/Subscriptions/AddEditSubscription'
import Emails from './Admin/Emails'
import Surveillance from './Surveillance/Home'
import UserSubscription from './Subscriptions/ViewSubscription'
import AddSubscriptionUser from './Subscriptions/AddEditSubscription'
import PridictionDashboard from './DiseasePrediction/Dashboard'
import RiskCalculator from './RiskCalculator'
import CovidPharma from './CovidPharma'
import CovidStateDashboard from './CovidPharma/CovidStateDashboard'
import CovidGeoDashboard from './CovidPharma/CovidGeoDashboard'
import FluMeter from './FluMeter'
import HeatMap from './HeatMap'
import FluTrend from './FluTrend'
import AccuracyTrend from './AccuracyTrend'
import SeasonTrend from './SeasonTrend'
import FluVaccination from './FluVaccination'
import FluVaccinationDetails from './FluVaccinationDetails'
import Respiratory from './Surveillance/Respiratory'
import ModelTabs from './About/ModelTab'
import ViewProfile from '../pages/Subscriptions/ViewSubscription'
import ImageGenerate from './Dashboard/ImageGenerate'
import { FEEDBACK_LINK } from '../utils/constants'
import SurveillanceNew from './SurveillanceNew'
import { useAuthState } from '../components/AuthProvider'
import RequireAuth from '../components/AuthProvider/RequireAuth'
const adminAccounts = ['Admin']
const otherAccounts = ['Basic', 'Power User']
const specialAccounts = ['Special User']
const allAccounts = [...otherAccounts, ...adminAccounts, ...specialAccounts]
const phiPii = ['Admin', 'Power User']
const allEnvironments = ['local', 'stage', 'prod']

const Pages = [
  {
    authorizedRoles: allAccounts,
    envAccess: allEnvironments,
    element: <Home />,
    label: 'Home',
    path: '/',

    routes: [
      {
        isHidden: false,
        authorizedRoles: allAccounts,
        envAccess: allEnvironments,
        element: (
          <RequireAuth>
            <Profile />
          </RequireAuth>
        ),
        path: '/profile',
        routes: [],
      },
      {
        isHidden: true,
        authorizedRoles: allAccounts,
        envAccess: allEnvironments,
        element: (
          <RequireAuth>
            <UserSubscription />
          </RequireAuth>
        ),
        label: 'Subscriptions',
        path: '/subscriptions',
        routes: [
          {
            authorizedRoles: adminAccounts,
            envAccess: allEnvironments,
            element: (
              <RequireAuth roles={adminAccounts}>
                <AddSubscriptionUser />
              </RequireAuth>
            ),
            label: 'Add Subscription',
            path: '/add',
          },
        ],
      },
      {
        authorizedRoles: allAccounts,
        envAccess: allEnvironments,
        element: (
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        ),
        label: 'Home',
        path: '/Home',
        routes: [],
      },
      {
        authorizedRoles: allAccounts,
        envAccess: allEnvironments,
        element: (
          <RequireAuth>
            <ImageGenerate />
          </RequireAuth>
        ),
        label: '',
        path: '/ImageGenerate',
        routes: [],
      },
      {
        authorizedRoles: allAccounts,
        envAccess: allEnvironments,
        element: (
          <RequireAuth>
            <ForecastDashboard />
          </RequireAuth>
        ),
        label: 'Forecasts',
        path: '/forecasts',
        routes: [
          {
            authorizedRoles: allAccounts,
            envAccess: allEnvironments,
            element: (
              <RequireAuth>
                <ForecastDashboard />
              </RequireAuth>
            ),
            label: 'Home',
            path: '/forecasts',
            isHidden: false,
            routes: [],
          },
          {
            isHidden: false,
            authorizedRoles: allAccounts,
            envAccess: allEnvironments,
            element: (
              <RequireAuth>
                <FluTabs />
              </RequireAuth>
            ),
            label: 'Influenza level',
            path: '/Flumap',
            routes: [],
          },
          {
            isHidden: true,
            authorizedRoles: allAccounts,
            envAccess: allEnvironments,
            element: (
              <RequireAuth>
                <FluDetails />
              </RequireAuth>
            ),
            label: 'Flu Charts',
            path: 'flu/:area/:loc',
            routes: [],
          },

          {
            authorizedRoles: allAccounts,
            envAccess: allEnvironments,
            element: (
              <RequireAuth>
                <CovidPharma />
              </RequireAuth>
            ),
            label: 'Covid Incidence',
            path: '/CovidDashboard',
            isHidden: false,
            routes: [],
          },
          {
            isHidden: false,
            authorizedRoles: allAccounts,
            envAccess: allEnvironments,
            element: (
              <RequireAuth>
                <HRRMap />
              </RequireAuth>
            ),
            label: 'Covid-19 Weekly Hospitalization',
            path: '/hrrmap',
            routes: [],
          },
        ],
      },
      {
        authorizedRoles: allAccounts,
        envAccess: allEnvironments,
        element: (
          <RequireAuth>
            <PridictionDashboard />
          </RequireAuth>
        ),
        label: 'Disease Prediction',
        path: '/DiseasePrediction',
        routes: [
          {
            authorizedRoles: allAccounts,
            envAccess: allEnvironments,
            element: (
              <RequireAuth>
                <PridictionDashboard />
              </RequireAuth>
            ),
            label: 'Home',
            path: '/home',
            routes: [],
          },
          {
            isHidden: false,
            authorizedRoles: phiPii,
            envAccess: allEnvironments,
            element: (
              <RequireAuth>
                <Vulnerability />
              </RequireAuth>
            ), //Added to restrict vul
            label: 'Vulnerability',
            path: '/Vulnerability',
            routes: [],
          },
          {
            isHidden: false,
            authorizedRoles: phiPii,
            envAccess: allEnvironments,
            element: (
              <RequireAuth>
                <Raredisease />
              </RequireAuth>
            ), //Added to restrict rare disease
            label: 'Rare Disease',
            path: '/RareDisease',
            routes: [],
          },
          {
            isHidden: false,
            authorizedRoles: allAccounts,
            envAccess: allEnvironments,
            element: (
              <RequireAuth>
                <RiskCalculator />
              </RequireAuth>
            ),
            label: 'Cardiac Risk Calculator',
            path: '/RiskCalculator',
            routes: [],
          },
        ],
      },
      {
        authorizedRoles: allAccounts,
        envAccess: allEnvironments,
        element: (
          <RequireAuth>
            <SurveillanceNew />
          </RequireAuth>
        ),
        label: 'Surveillance',
        path: '/Surveillance',
        routes: [
        ],
      },
      // {
      //   authorizedRoles: allAccounts,
      //   envAccess: allEnvironments,
      //   element: requiresAuth(FluSurveillance),
      //   label: 'Surveillance',
      //   path: '/Surveillance',

      //   routes: [
      //     {
      //       authorizedRoles: allAccounts,
      //       envAccess: allEnvironments,
      //       element: requiresAuth(Surveillance),
      //       label: String.raw`Home`,
      //       path: '/Home',
      //       routes: [],
      //     },
      //     {
      //       authorizedRoles: allAccounts,
      //       envAccess: allEnvironments,
      //       element: requiresAuth(FluSurveillance),
      //       label: String.raw`Flu`,
      //       path: '/flu',

      //       routes: [],
      //     },
      //     {
      //       authorizedRoles: allAccounts,
      //       envAccess: allEnvironments,
      //       element: requiresAuth(Respiratory),
      //       label: String.raw`Respiratory`,
      //       path: '/respiratory',

      //       routes: [],
      //     },
      //     {
      //       isHidden: true,
      //       authorizedRoles: allAccounts,
      //       envAccess: allEnvironments,
      //       element: requiresAuth(FluSurveillance),
      //       path: '/covid',
      //       label: 'Covid-19',
      //       routes: [],
      //     },
      //     {
      //       isHidden: true,
      //       authorizedRoles: allAccounts,
      //       envAccess: allEnvironments,
      //       element: requiresAuth(FluSurveillance),
      //       path: '/pasc',
      //       label: 'PASC',
      //       routes: [],
      //     },
      //   ],
      // },

      {
        authorizedRoles: allAccounts,
        envAccess: allEnvironments,
        element: (
          <RequireAuth>
            <FluVaccination />
          </RequireAuth>
        ),
        label: 'Vaccinations',
        path: '/Vaccinations',

        routes: [
          {
            isHidden: true,
            authorizedRoles: allAccounts,
            envAccess: allEnvironments,
            element: (
              <RequireAuth>
                <FluVaccination />
              </RequireAuth>
            ),

            path: '/fluvaccination',
            routes: [],
          },
          {
            isHidden: true,
            authorizedRoles: allAccounts,
            envAccess: allEnvironments,
            element: (
              <RequireAuth>
                <FluVaccinationDetails />
              </RequireAuth>
            ),
            label: 'Flu Vaccination Details',
            path: ':area',
          },
        ],
      },
      {
        authorizedRoles: allAccounts,
        envAccess: allEnvironments,
        element: (
          <RequireAuth>
            <ModelTabs />
          </RequireAuth>
        ),
        label: 'About Models',
        path: '/about',

        routes: [
          {
            authorizedRoles: allAccounts,
            envAccess: allEnvironments,
            element: (
              <RequireAuth>
                <ModelTabs />
              </RequireAuth>
            ),
            label: 'Model',
            path: '/model',
            isHidden: true,
            routes: [],
          },
          {
            authorizedRoles: allAccounts,
            envAccess: allEnvironments,
            element: (
              <RequireAuth>
                <ModelTabs />
              </RequireAuth>
            ),
            label: String.raw`Model Information`,
            path: '/model#information',

            routes: [],
          },
          {
            authorizedRoles: allAccounts,
            envAccess: allEnvironments,
            element: (
              <RequireAuth>
                <ModelTabs />
              </RequireAuth>
            ),
            label: String.raw`Model Accuracy`,
            path: '/model#accuracy',

            routes: [],
          },
          {
            authorizedRoles: allAccounts,
            envAccess: allEnvironments,
            element: <Feedback />,
            externalLink: FEEDBACK_LINK,
            path: '/feedback',
            label: String.raw`Feedback &
Suggestions`,
            new: false,
            routes: [],
          },
          {
            isHidden: true,
            authorizedRoles: allAccounts,
            envAccess: allEnvironments,
            element: (
              <RequireAuth>
                <ViewProfile />
              </RequireAuth>
            ),
            label: 'ViewProfile',
            path: '/Viewprofile',

            routes: [],
          },
        ],
      },
      {
        authorizedRoles: adminAccounts,
        envAccess: allEnvironments,
        element: (
          <RequireAuth roles={adminAccounts}>
            <UsersPage />
          </RequireAuth>
        ),
        label: 'Admin',
        path: '/admin',
        allowedRoles: adminAccounts,
        routes: [
          {
            isHidden: true,
            authorizedRoles: adminAccounts,
            envAccess: allEnvironments,
            element: (
              <RequireAuth roles={adminAccounts}>
                <Emails />
              </RequireAuth>
            ),
            label: 'Emails',
            path: '/emails',
          },
          {
            authorizedRoles: adminAccounts,
            envAccess: allEnvironments,
            element: (
              <RequireAuth roles={adminAccounts}>
                <UsersPage />
              </RequireAuth>
            ),

            label: 'Users',
            path: '/users',
            routes: [
              {
                authorizedRoles: adminAccounts,
                envAccess: allEnvironments,
                element: (
                  <RequireAuth roles={adminAccounts}>
                    <AddEditUser />
                  </RequireAuth>
                ),
                label: 'Add User',
                path: '/add',
              },
              {
                authorizedRoles: adminAccounts,
                envAccess: allEnvironments,
                element: (
                  <RequireAuth roles={adminAccounts}>
                    <UsersPage />
                  </RequireAuth>
                ),
                label: 'View User',
                path: '/:id',
                routes: [
                  {
                    authorizedRoles: adminAccounts,
                    envAccess: allEnvironments,
                    element: (
                      <RequireAuth roles={adminAccounts}>
                        <UsersPage />
                      </RequireAuth>
                    ),
                    label: 'Edit User',
                    path: '/edit',
                  },
                ],
              },
            ],
          },
          {
            authorizedRoles: adminAccounts,
            envAccess: allEnvironments,
            element: (
              <RequireAuth roles={adminAccounts}>
                <SystemNotificationsPage />
              </RequireAuth>
            ),
            label: 'System Notifications',
            path: '/systemnotifications',
            routes: [
              {
                authorizedRoles: adminAccounts,
                envAccess: allEnvironments,
                element: (
                  <RequireAuth roles={adminAccounts}>
                    <SystemNotificationsPage />
                  </RequireAuth>
                ),
                label: 'Add System Notifications',
                path: '/add',
              },
              {
                authorizedRoles: adminAccounts,
                envAccess: allEnvironments,
                element: (
                  <RequireAuth roles={adminAccounts}>
                    <SystemNotificationsPage />
                  </RequireAuth>
                ),
                label: 'View System Notifications',
                path: '/:id',
                routes: [
                  {
                    authorizedRoles: adminAccounts,
                    envAccess: allEnvironments,
                    element: (
                      <RequireAuth roles={adminAccounts}>
                        <SystemNotificationsPage />
                      </RequireAuth>
                    ),
                    label: 'Edit System Notifications',
                    path: '/edit',
                  },
                ],
              },
            ],
          },
          {
            authorizedRoles: adminAccounts,
            envAccess: allEnvironments,
            element: (
              <RequireAuth roles={adminAccounts}>
                <Subscriptions />
              </RequireAuth>
            ),
            label: 'Subscriptions',
            path: '/subscriptions',
            routes: [
              {
                authorizedRoles: adminAccounts,
                envAccess: allEnvironments,
                element: (
                  <RequireAuth roles={adminAccounts}>
                    <AddSubscription />
                  </RequireAuth>
                ),
                label: 'Add Subscription',
                path: '/add',
              },
            ],
          },
          {
            authorizedRoles: adminAccounts,
            envAccess: allEnvironments,
            element: (
              <RequireAuth roles={adminAccounts}>
                <UserActivity />
              </RequireAuth>
            ),
            label: 'Users Activity',
            path: '/usersactivity',
          },
        ],
      },
      {
        authorizedRoles: adminAccounts,
        envAccess: allEnvironments,
        element: (
          <RequireAuth roles={adminAccounts}>
            <AdminPages />
          </RequireAuth>
        ),
        label: 'AdminPages',
        path: '/adminpage',
        allowedRoles: adminAccounts,
        isHidden: true,
        routes: [
          {
            authorizedRoles: adminAccounts,
            envAccess: allEnvironments,
            element: (
              <RequireAuth roles={adminAccounts}>
                <UsersPage />
              </RequireAuth>
            ),
            label: 'Users',
            path: '/users',
            routes: [
              {
                authorizedRoles: adminAccounts,
                envAccess: allEnvironments,
                element: (
                  <RequireAuth roles={adminAccounts}>
                    <UsersPage />
                  </RequireAuth>
                ),
                label: 'Add User',
                path: '/add',
              },
              {
                authorizedRoles: adminAccounts,
                envAccess: allEnvironments,
                element: (
                  <RequireAuth roles={adminAccounts}>
                    <UsersPage />
                  </RequireAuth>
                ),
                label: 'View User',
                path: '/:id',
                routes: [
                  {
                    authorizedRoles: adminAccounts,
                    envAccess: allEnvironments,
                    element: (
                      <RequireAuth roles={adminAccounts}>
                        <UsersPage />
                      </RequireAuth>
                    ),
                    label: 'Edit User',
                    path: '/edit',
                  },
                ],
              },
            ],
          },
          {
            authorizedRoles: adminAccounts,
            envAccess: allEnvironments,
            element: (
              <RequireAuth roles={adminAccounts}>
                <SystemNotificationsPage />
              </RequireAuth>
            ),
            label: 'System Notifications',
            path: '/systemnotifications',
            routes: [
              {
                authorizedRoles: adminAccounts,
                envAccess: allEnvironments,
                element: (
                  <RequireAuth roles={adminAccounts}>
                    <SystemNotificationsPage />
                  </RequireAuth>
                ),
                label: 'Add System Notifications',
                path: '/add',
              },
              {
                authorizedRoles: adminAccounts,
                envAccess: allEnvironments,
                element: (
                  <RequireAuth roles={adminAccounts}>
                    <SystemNotificationsPage />
                  </RequireAuth>
                ),
                label: 'View System Notifications',
                path: '/:id',
                routes: [
                  {
                    authorizedRoles: adminAccounts,
                    envAccess: allEnvironments,
                    element: (
                      <RequireAuth roles={adminAccounts}>
                        <SystemNotificationsPage />
                      </RequireAuth>
                    ),
                    label: 'Edit System Notifications',
                    path: '/edit',
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        isHidden: true,
        authorizedRoles: allAccounts,
        envAccess: allEnvironments,
        element: (
          <RequireAuth>
            <CovidPharma />
          </RequireAuth>
        ),
        label: 'CovidDashboard',
        path: '/CovidStateDashboard',
        routes: [
          {
            isHidden: true,
            authorizedRoles: allAccounts,
            envAccess: allEnvironments,
            element: (
              <RequireAuth>
                <CovidStateDashboard />
              </RequireAuth>
            ),
            label: 'State View',
            path: ':area/:loc',
          },
        ],
      },
      {
        isHidden: true,
        authorizedRoles: allAccounts,
        envAccess: allEnvironments,
        element: (
          <RequireAuth>
            <CovidPharma />
          </RequireAuth>
        ),
        label: 'CovidDashboard',
        path: '/CovidMSADashboard',

        routes: [
          {
            isHidden: true,
            authorizedRoles: allAccounts,
            envAccess: allEnvironments,
            element: (
              <RequireAuth>
                <CovidGeoDashboard />
              </RequireAuth>
            ),
            label: 'MSA View',
            path: ':geo_parent/:area/:loc',
          },
        ],
      },
    ],
  },

  {
    authorizedRoles: allAccounts,
    envAccess: allEnvironments,
    element: <FluMeter />,
    path: '/flumeter',
  },
  {
    authorizedRoles: allAccounts,
    envAccess: allEnvironments,
    element: <HeatMap />,
    path: '/heatmap',
  },
  {
    authorizedRoles: allAccounts,
    envAccess: allEnvironments,
    element: <FluTrend />,
    path: '/flutrend',
  },
  {
    authorizedRoles: allAccounts,
    envAccess: allEnvironments,
    element: <AccuracyTrend />,
    label: 'Flu Accuracy Comparison',
    path: 'AccuracyTrend/:area/:loc',
  },
  {
    authorizedRoles: allAccounts,
    envAccess: allEnvironments,
    element: <SeasonTrend />,
    label: 'Season Influenza Pattern',
    path: 'SeasonTrend/:area/:loc',
  },

  {
    authorizedRoles: allAccounts,
    envAccess: allEnvironments,
    element: <Login />,
    path: '/login',
  },
  {
    authorizedRoles: allAccounts,
    envAccess: allEnvironments,
    element: <OneHealthcareIdLogin />,
    path: '/ohid-login',
  },
  {
    authorizedRoles: allAccounts,
    envAccess: allEnvironments,
    element: <ActiveDirectoryLogin />,
    path: '/ad-login',
  },
  {
    authorizedRoles: allAccounts,
    envAccess: allEnvironments,
    element: <LoginRedirect />,
    path: '/oauth2/login-redirect',
  },
  {
    authorizedRoles: allAccounts,
    envAccess: allEnvironments,
    element: <ActiveDirectoryLoginRedirect />,
    path: '/msid/login-redirect',
  },
  {
    authorizedRoles: allAccounts,
    envAccess: allEnvironments,
    element: <SignOut />,
    path: '/signout',
  },
  {
    authorizedRoles: allAccounts,
    envAccess: allEnvironments,
    element: <ActiveDirectorySignOut />,
    path: '/ad-signout',
  },
  {
    authorizedRoles: allAccounts,
    envAccess: allEnvironments,
    element: <NoAccess />,
    path: '/access-denied',
  },
  {
    authorizedRoles: allAccounts,
    envAccess: allEnvironments,
    element: <AccessAbort />,
    path: '/accessabort',
  },
  {
    authorizedRoles: allAccounts,
    envAccess: allEnvironments,
    element: <NotFound />,
    path: '*',
  },
]

export default Pages
