import React, { useState, useEffect } from 'react'
import CoverageMap from './CoverageMap'

const UHCCoverageMap = ({chartRef}) => {
  const [getStateJsonFile, setStateJsonFile] = useState();
  useEffect(() => {
    let url = `${window.location.origin}/usStatesAll.json`
    fetch(url)
      .then(function (res) {       
        return res.json();
      })
      .then(function (data) {
       setStateJsonFile(data);
      })
      .catch(function (err) {
        console.log(err, " error file load usStatesAll ");
      });
  }, [1]);

  return (
         getStateJsonFile ? (
         
            <CoverageMap  stateJson={getStateJsonFile}  chartRef={chartRef} />
       
        ) : (
          ''
        )
  )
}

export default UHCCoverageMap
