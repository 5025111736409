import { dateWeekToolTip,dateWeekToolTipNew,surveillanceCompareToolTip } from './toolTipFormatter'
import HT from 'highcharts/highcharts-more'
import Highcharts from 'highcharts'
HT(Highcharts)
const getLineChartOptions = (
  data,
  weeks,
  currentWeekIndex,
  currentWeek,
  chartName = '',
  SETINTERVAL,
  chartType,
) => {
  return {
    title: {
      text: chartName,
    },

    xAxis: {
      crosshair:{color:'#E5E5E6'},
      title: {
        text: chartType==="SURV"?'':'Epiweek',
        style: {
          color: Highcharts.getOptions().colors[1],
          fontSize: '14px',
        },
      },
      categories: weeks,
      labels: {
        style: {
          fontSize: '13px',
          fontWeight: '400',
          color: '#4B4D4F',
        },
        
      },
      lineColor: '#929496',
      lineWidth: 1,
      plotLines: [
        {
          color: '#cdcdcd',
          width: 1,
          value: currentWeekIndex,
          zIndex: 10,
          label: {
            x: -20,
            rotation: 0,
            text: 'Epiweek ' + currentWeek,
            style: { color: '#4B4D4F', fontSize: '12px' },
          },
        },
      ],
    },
    tooltip: chartType==="SURV"?{
      shared: true,
      crosshairs: true,
      formatter:surveillanceCompareToolTip,
      borderRadius: 10,
      padding: 1,
      shadow: false,
      useHTML: true,
      style: {
        padding: 0,
      },
    }:{shared: true,
      crosshairs: true,
      formatter:SETINTERVAL? dateWeekToolTipNew : dateWeekToolTip,
      borderRadius: 10,
      padding: 1,
      shadow: false,
      useHTML: true,
      style: {
        padding: 0,
      },},
    yAxis: {
      title: {
        text: 'Incidence',
        style: {
          color: Highcharts.getOptions().colors[1],
          fontSize: '14px',
        },
      },
      labels: {
        style: {
          fontSize: '16px',
          fontWeight: '400',
          color: '#4B4D4F',
        },
      },
      gridLineColor: '#CBCCCD',
    },

    credits: {
      enabled: false,
    },

    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
      itemStyle: {
        fontSize: '12px',
       
     },
      
    },

    series: data,

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom',
            },
          },
        },
      ],
    },
  }
}

export default getLineChartOptions
