import React, { useState, useEffect, useRef } from 'react'
import { useGeoState, useGeoFetch } from '../../../components/GeoProvider'
import Spinner from '../../../components/Loader/Spinner'
import UHCDataTable from '../UHCDataTable'
import UHCSideTable from '../UHCSideTable'
import styles from '../MapPage.module.scss'
import UHCCoverageMap from './UHCCoverageMap'


const UHCMain = () => {
  const chartRef = useRef(null)
  const [getCoverageData, setCoverageData] = useState(undefined)

  const { showFullTable } = useGeoState()

  // States Error Metric Data
  const { data: coverageData, error: coverageDataError } = useGeoFetch(
    '',
    '',
    'surveillance',
    'uhccoverage'
  )
  useEffect(() => {
    setCoverageData(coverageData)
  }, [coverageData])


  return (
    <>
      {showFullTable && getCoverageData !== undefined ? (
        <div className={`${styles.datePanel}`}>
          <UHCDataTable coverageData={getCoverageData} chartRef={chartRef} />
        </div>
      ) : (
        <div className={`${styles.cbsasPanel}`}>
          <>
            {getCoverageData !== undefined ? (
              <UHCSideTable
                coverageData={getCoverageData}
                chartRef={chartRef}
              />
            ) : (
              <div style={{ border: '1.5px solid #CBCCCD', height: '84vh' }}>
                <Spinner />
              </div>
            )}
          </>
        </div>
      )}
      <div className={`${styles.map}`}>
        <UHCCoverageMap chartRef={chartRef} />
      </div>
    </>
  )
}

export default UHCMain
