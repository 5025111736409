import { React } from 'react'
import LinkButton from '../../components/LinkButton'
import styles from './UHCDataTable.module.scss'
import ReactSwitch from 'react-switch'
import { useGeoState } from '../../components/GeoProvider'
import ExpandArrowLeft from '../../assets/images/icon/arrow_expand_left.png'
import dataDisplayOptions from './dataDisplayOptions'
import countingMethod from './../../utils/getCountingMethod'
import dayjs from 'dayjs'

const UHCDataTable = ({coverageData,  chartRef }) => {
  
  const { showUHCCoverage, setUHCCoverage,showFullTable,
     setShowFullTable,regionSelected } = useGeoState()
  const sundayDate = dayjs().day(0).format('YYYY-MM-DD')
  let mapOptions=dataDisplayOptions[0]['options'][1]
  const handleChange = (val) => {
    setUHCCoverage(val)
  }
  const handleMouseOver = (element) => {
    const chart = chartRef.current.chart
    var indexSeries = regionSelected === "metro" ? 1 :regionSelected === "zip3"? 2: 0
    if (chart !== undefined && chart !== null) {
      const point = chart.series[indexSeries].points.find(
        (point) => point.geo_id === element.attributes['geo_id'].textContent
        //element.getAttribute('title')
      )
      point && chart.tooltip.refresh(point)
    }
  }
  const handleMouseOut = () => {
    const chart = chartRef.current.chart
    if (chart && chart.tooltip) {
      chart.tooltip.destroy()
    }
  }
  const handleFullTableChange = () => {
    setShowFullTable(!showFullTable)
  }
  const stateDateData = Object.entries(coverageData[sundayDate])

   let filteredStateData = stateDateData.map((state) => {
      return {
        value: state[1].MR!==undefined?state[1].MR[mapOptions.value]:0,
        state_per: state[1].MR!==undefined?state[1].MR[mapOptions.value]:0,
        state_count: state[1].MR!==undefined?state[1].MR[mapOptions.stateCount]:0,
        mr: state[1].MR!==undefined?state[1].MR[mapOptions.lobValue]:0,
        mr_per: state[1].MR!==undefined?state[1].MR[mapOptions.lobPer]:0,
        ei: state[1].EI!==undefined?state[1].EI[mapOptions.lobValue]:0,
        ei_per: state[1].EI!==undefined?state[1].EI[mapOptions.lobPer]:0,
        cs: state[1].CS!==undefined? state[1].CS[mapOptions.lobValue]:0,
        cs_per: state[1].CS!==undefined?state[1].CS[mapOptions.lobPer]:0,
        population: countingMethod(state[1].MR!==undefined?state[1].MR[mapOptions.popCount]:0, 'fixed'),
        state: state[0],
        ...state[1].MR,
      }
    })

  return  (
    <div className={styles.base}>
       <div
          style={{
            marginTop: '16px',
            padding:'0px 16px 0px 10px'
          }}
        >
          <div style={{ display: 'flex' }}>
            <div
              style={{
                display: 'inline-flex',
                width: '70%',
              }}
            >
              <span>
                <h5
                  style={{
                    fontFamily: 'Optum Sans',
                    fontSize: '21px',
                    fontWeight: '600',
                    color: '#002677',
                  }}
                >
                  State 
                  
                </h5>
              </span>
            </div>
            <div  style={{
                display: 'inline-flex',
                width: '30%',
                flexDirection: 'row-reverse',
              }} >
              <img
                src={ExpandArrowLeft}
                alt="expand-right"
                onClick={handleFullTableChange}
                style={{ height: '20px', width: '20px', marginBottom: '-3px' ,cursor:'pointer'}}
              ></img>
              </div>
          </div>
         
        </div>
       
          
          <div
            style={{
              display: 'flex',
             
              justifyContent: 'flex-end',
              paddingBottom: '16px',
              paddingTop: '16px',
              paddingRight:'16px'
            }}
          >
            <span className={styles.showOnMap}>UHC Coverage</span>
            <ReactSwitch
              onColor="#196ECF"
              offColor="#7D7F81"
              height={20}
              width={40}
              uncheckedIcon={false}
              checkedIcon={false}
              handleDiameter={15}
              checked={showUHCCoverage}
              onChange={handleChange}
            />
          </div>
      <div className={styles.maindivFormat}>
        
          <table>
            <thead>
              <tr>
                <th>Area Name</th>
                <th>Pop.</th>
                <th style={{borderRight:'2px solid #002677'}}>Pct. %</th>
                <th>E&I</th>
                <th>M&R</th>
                <th style={{borderRight:'2px solid #002677'}}>C&S</th>
              </tr>
            </thead>
            <tbody style={{fontSize:'14px'}}>
            {
              filteredStateData.length === 0 ? (
                <tr>
                  <td style={{ textAlign: 'center' }} colSpan={5}>
                    No data to show
                  </td>
                </tr>
              ) : (
             
 
                filteredStateData.map((data_val) => {
      
    return(
    <tr style={{borderBottom:'1px solid #ddd'}}>
    <td>
    <LinkButton
         
          className={styles.btn}
          onMouseOver={(e) => handleMouseOver(e.target)}
          onMouseOut={() => handleMouseOut()}
          data-title={data_val.geo_name}
          title={data_val.geo_name} 
          geo_id={data_val.geo_id}
        >
             {data_val.geo_name} 
        </LinkButton>
    </td>
    <td>{(countingMethod(data_val.population_count!==undefined?data_val.population_count:0,'fixed'))}</td>
    <td style={{borderRight:'2px solid #002677'}}>{(data_val.state_per.toFixed(2))}%</td>
    <td>{(data_val.ei_per.toFixed(2))}%</td>
    <td>{(data_val.mr_per.toFixed(2))}%</td>
    <td style={{borderRight:'2px solid #002677'}}>{(data_val.cs_per.toFixed(2))}%</td>

    </tr>
            )
    
   })

  )
   }
            </tbody>
          </table>
        </div>

    </div>
    
  ) 
}

export default UHCDataTable
