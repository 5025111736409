import React from 'react'
import { useNavigate } from 'react-router-dom'

//import usCountiesAll from '../../../../components/Map/cb_2018_us_county_20m_wkid102004.json1'
import Button from '../../../../components/Button'

import statesIdMap from '../../../../../src/utils/stateIdMap'
import statesMap from '../../../../../src/utils/stateMap'

import styles from './CbsaStats.module.scss'
import statStyles from './CbsaStat.module.scss'

const CbsaStat = ({ cbsa }) => {
  let icon = null

  const history = useNavigate()
  const states = statesMap()

  if (cbsa.trending_percentage === 0) icon = ''
  else if (cbsa.trending_percentage > 0) icon = '&#8593;'
  else if (cbsa.trending_percentage < 0) icon = '&#8595;'

  return (
    <div style={{}} className={statStyles.base}>
      <div style={{ display: 'inline-flex', width: '65%' }}>
        <Button
          className={statStyles.btn}
          onClick={() => {
            // history(`/forecasts/map#county-${cbsa.identifier}`)
            history(`/forecasts/covidmap#county-${cbsa.identifier}`)
          }}
          title="Click to view details"
        >
          {cbsa.name}
        </Button>
        <div
          style={{
            textTransform: 'uppercase',
            opacity: '0.6',
            fontSize: '0.8rem',
            position: 'relative',
            top: '1.1rem',
            left: '5px',
          }}
        >
          {states[cbsa.state]}
        </div>
      </div>
      <div
        style={{ display: 'inline-flex', width: '15%' }}
        dangerouslySetInnerHTML={{ __html: icon }}
        title="Trending"
      />
    </div>
  )
}

const CountyStats = ({countyJson, stateJson}) => {
  const [filteredCountyData, setFilteredCountyData] = React.useState([])
  const states = statesMap()
  const statesId = statesIdMap(stateJson)

  const handleChange = (event) => {
    if (event.target.value.length >= 2) {
      const filteredData = countyJson.features
        .map((f) => {
          return {
            ...f,
            stateCode: statesId[f.properties['STATEFP']],
          }
        })
        .filter((e) => {
          if (
            'NAME' in e.properties &&
            (e.properties.NAME.toLowerCase().includes(
              event.target.value.toLowerCase()
            ) ||
              e.stateCode
                .toLowerCase()
                .includes(event.target.value.toLowerCase()) ||
              states[e.stateCode.toUpperCase()].toLowerCase() ===
                event.target.value.toLowerCase())
          ) {
            return e
          } else {
            return undefined
          }
        })
        .map((c) => {
          return {
            name: c.properties.NAME,
            identifier: c.properties.GEOID,
            state: c.stateCode,
          }
        })
      setFilteredCountyData(filteredData)
    } else if (event.target.value.length > 0) {
      setFilteredCountyData([])
    }
  }

  return (
    <div className={styles.base}>
      <div className={styles.header}>
        <div>
          <h5>County Search </h5>
          <form>
            <input
              type="text"
              onChange={handleChange}
              placeholder="Ex: TX, Texas, or Hays"
            />
          </form>
        </div>
        <br />
      </div>
      <div style={{ overflow: 'scroll', height: '100%' }}>
        {filteredCountyData.length > 0 &&
          filteredCountyData
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((cbsa) => {
              return <CbsaStat cbsa={cbsa} key={cbsa.identifier} />
            })}
        {filteredCountyData.length === 0 && (
          <p style={{ padding: '1rem' }}>
            Enter the search field above using county name or state code or name
            to see a list of counties.
          </p>
        )}
      </div>
    </div>
  )
}

export default CountyStats
