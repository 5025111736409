import React,{useState,useEffect} from 'react'
import { useGeoState, useGeoFetch } from '../../components/GeoProvider'
import ErrorMessage from '../../components/Layout/ErrorMessage'
import Map from '../../components/Map'
import EmptyMap from '../../components/Map/emptyMap'
import toolTipFormatter from './toolTipFormatter'
import colorRanges from './colorAxisFluIndex'
import MapLegend from './cdcMapLegendVertical'

import styles from './FluMeter.module.scss'

const FluMap = () => {
  const entity = 'flumeter'  
  const { dataStartDate, dataEndDate, mapOptions, regionSelected } =
    useGeoState()

  const { data: statesData, error: stateDataError } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    entity,
    'state'
  )
  const { data: cbsasData, error: cbsasDataError } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    entity,
    'city'
  )

  const { data: activeCbsasData, error: activeCbsasDataError } = useGeoFetch(
    dataStartDate,
    dataEndDate,
    entity,
    'flu/activecbsa'
  )
  const [getStateJsonFile, setStateJsonFile] = useState();
  useEffect(() => {
    let url = `${window.location.origin}/usStatesAll.json`
    fetch(url)
      .then(function (res) {       
        return res.json();
      })
      .then(function (data) {
       setStateJsonFile(data);
      })
      .catch(function (err) {
        console.log(err, " error file load usStatesAll ");
      });
  }, [1]);

  if (stateDataError || cbsasDataError || activeCbsasDataError) {
    return <ErrorMessage />
  }

  return statesData && cbsasData && activeCbsasData && getStateJsonFile ? (
    <>
      <div className={styles.container}>
        <div className={styles.map}>
          {regionSelected === 'state'  && (
            <Map
              colorRanges={colorRanges}
              showStateLabels={true}
              toolTipFormatter={toolTipFormatter}
              activeCbsasData={null}
              statesData={statesData}
              cbsaData={null}
              mapOptions={mapOptions}
              mapHeight={'95vh'}
              dynamicMapTitle={true}
              // dynamicMapLevelWeek={true}
              mapType="HeatMap"
              stateJson={getStateJsonFile}
            />
          )}
          {regionSelected === 'metro' && (
            <Map
              colorRanges={colorRanges}
              showStateLabels={true}
              toolTipFormatter={toolTipFormatter}
              activeCbsasData={activeCbsasData}
              statesData={statesData}
              cbsaData={cbsasData}
              mapOptions={mapOptions}
              mapHeight={'95vh'}
              dynamicMapTitle={true}
              // dynamicMapLevelWeek={true}
              mapType="HeatMap"
            />
          )}
          <div className={styles.legend}>
            <MapLegend height="310" width="120" />
          </div>
        </div>
      </div>
    </>
  ) : (
    <EmptyMap mapHeight={'95vh'} />
  )
}

export default FluMap
