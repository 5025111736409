import React, { useState } from 'react'
import Content from '../../components/Content'
import Layout from '../../components/Layout'
import GeoProvider from '../../components/GeoProvider'
import styles from './FluVaccinationDetails.module.scss'
import DashboardHeader from './DashboardHeader'
import TopGraphPanel from './TopGraphPanel'
import BottomGraphPanel from './BottomGraphPanel'
import { startDate, endDate, displayDate } from './dates'

// import { startDate, endDate, displayDate } from './dates'
import { useAuthState } from '../../components/AuthProvider'
import activityLog from '../../utils/activityLog'
const FluVaccinationDetails = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const urlState = queryParams.get('state')
  const [getfilterts, filtersObj] = useState({
    state: urlState,
    season: '11',
    month: 'All',
  })
  const { user } = useAuthState()
  // var url = window.location.href
  // var name = ''
  // if (url.indexOf('FluVaccinationDetails') !== -1)
  //   name = 'Flu Vaccination Details'

  var logObject = {
    dashboard_url: window.location.href,
    dashboard_name: 'Flu Vaccination Details',
    indicator_name: 'Flu Vaccination Details',
    geo_name: '',
    geo_type: '',
    user_name: user.username,
    email: user.email,
  }
  activityLog(logObject)

  const seasonData = (e) => {
    filtersObj({ ...getfilterts, season: e === '' ? 'All' : e })
  }
  const monthData = (e) => {
    filtersObj({ ...getfilterts, month: e === '' ? 'All' : e })
  }
  return (
    <Layout>
      <Content fontSize={'14px'}>
        <div className={styles.base}>
          <div className={styles.header}>
            <div className={styles.content}>
              <GeoProvider
                startDate={startDate}
                endDate={endDate}
                displayDate={displayDate}
              >
                <DashboardHeader
                  getfilterts={getfilterts}
                  urlState={urlState}
                  seasonData={seasonData}
                  monthData={monthData}
                />
                <TopGraphPanel getfilterts={getfilterts} />
                <BottomGraphPanel getfilterts={getfilterts} />
              </GeoProvider>
            </div>
          </div>
        </div>
      </Content>
    </Layout>
  )
}

export default FluVaccinationDetails
