import React,{useState,useEffect} from 'react'
import {  useGeoFetch } from '../../../../components/GeoProvider'
import GeoProvider from '../../../../components/GeoProvider'
import FluAccuracyMap from './FluAccuracyMap'
import dataDisplayOptions from './dataDisplayOptions'
import { startDate, endDate, displayDate } from '../dates'
import { useAuthState } from '../../../../components/AuthProvider'
import activityLog from '../../../../utils/activityLog'
import Spinner from '../../../../components/Loader/Spinner'
const FluTabs = () => {
  const { user } = useAuthState()
  
  var logObject = {
    dashboard_url: window.location.href,
    dashboard_name: 'Model Accuracy',
    indicator_name: 'Model Accuracy',
    geo_name: '',
    geo_type: '',
    user_name: user.username,
    email: user.email,
  }
  activityLog(logObject)
  useEffect(() => {
    
  }, [1])
 
  const [getStateJsonFile, setStateJsonFile] = useState();
  useEffect(() => {
    let url = `${window.location.origin}/usStatesAll.json`
    fetch(url)
      .then(function (res) {       
        return res.json();
      })
      .then(function (data) {
       setStateJsonFile(data);
      })
      .catch(function (err) {
        console.log(err, " error file load usStatesAll ");
      });
  }, [1]);
  

  return (
    <GeoProvider
      startDate={startDate}
      endDate={endDate}
      displayDate={displayDate}
      mapOptions={dataDisplayOptions[0]['options'][0]}
      diseaseSelected={'flu'}
    >
     {getStateJsonFile ? (
     <FluAccuracyMap stateJson={getStateJsonFile} />
     ):(
     <Spinner></Spinner>
     )}
    </GeoProvider>
  )
}

export default FluTabs
