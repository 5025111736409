import React, { useState , useEffect} from 'react'
import styles from './DashboardHeader.module.scss'

import Dropdown from '../FluVaccination/Dropdown'
import CountPanelDetails from './CountPanelDetails'
import { useGeoFetch } from '../../components/GeoProvider'
import ErrorMessage from '../../components/Layout/ErrorMessage'
import Spinner from '../../components/Loader/Spinner'
import dropdown_arrow from '../../assets/images/dropdown-arrow.png';
const DashboardHeader = ({ getfilterts, urlState, seasonData, monthData }) => {
  const dataFluSeason = [
    {
      value: 11,
      label: '2023-24',
    },
    {
      value: 10,
      label: '2022-23',
    },
    {
      value: 9,
      label: '2021-22',
    },
    {
      value: 8,
      label: '2020-21',
    },
    {
      value: 7,
      label: '2019-20',
    },
    {
      value: 6,
      label: '2018-19',
    },
    {
      value: 5,
      label: '2017-18',
    },
    {
      value: 4,
      label: '2016-17',
    },
    {
      value: 3,
      label: '2015-16',
    },
    {
      value: 2,
      label: '2014-15',
    },
    
  ]
  const dataMonth = [
    {
      value: 1,
      label: 'January',
    },
    {
      value: 2,
      label: 'Febuary',
    },
    {
      value: 3,
      label: 'March',
    },
    {
      value: 4,
      label: 'April',
    },
    {
      value: 5,
      label: 'May',
    },
    {
      value: 6,
      label: 'June',
    },
    {
      value: 7,
      label: 'July',
    },
    {
      value: 8,
      label: 'August',
    },
    {
      value: 9,
      label: 'September',
    },
    {
      value: 10,
      label: 'October',
    },
    {
      value: 11,
      label: 'November',
    },
    {
      value: 12,
      label: 'December',
    },
  ]
  const [isHovering, setIsHovering] = useState('hidden')
  const [getCountData, setCountData] = useState()

  const handleMouseOver = () => {
    setIsHovering('visible')
  }
  const handleMouseOut = () => {
    setIsHovering('hidden')
  }
 
  const entity = 'FluVaccine'
  const location = 'DetailsDataCounts'
  const startDate = ''
  const endDate = ''
  const month = getfilterts.month
  const fluseason = getfilterts.season
  const state = getfilterts.state
  const fluFolder = 'DetailsDataCounts'
  const lob = getfilterts.lob
  const type = getfilterts.type
 
  const { data, error } = useGeoFetch(
    startDate,
    endDate,
    entity,
    `${location}?lob=${lob}&type=${type}&fluseason=${fluseason}&month=${month}&fluFolder=${fluFolder}&state=${state}`
  )

  useEffect(() => {
    if (data !== null && data !== undefined) {
     
      if (data.response_code === 200) {
        setCountData(data.response_data)
       }
       
    }
  }, [data])

  if (error) {
    return <ErrorMessage />
  }


  return (
    <div className={styles.base}>
      <div className={styles.titlePanel}>
        <h2>{urlState}</h2>
      </div>

      {getCountData ? <CountPanelDetails data={getCountData} /> : <Spinner />}

      <div
        onMouseEnter={handleMouseOver}
        onMouseLeave={handleMouseOut}
        className={styles.filterPanel}
      >
        <span id="filter-text1">Filter Available</span>
        <br />
        <span id="filter-text">
          <span id="list_type">Flu Season, Month</span>
        </span>       
         <img
          id="open-filter"
          className={styles.arrow}
          src={dropdown_arrow}
        />

        <div
          style={{ visibility: isHovering }}
          className={styles.filterdivpopup}
        >
          <div>
            <div>Flu Season</div>
            <div>
              <Dropdown
                dataType="season"
                seasonData={seasonData}
                data={dataFluSeason}
              />
            </div>
          </div>
          <div>
            <div>Month</div>
            <div>
              <Dropdown
                dataType="month"
                monthData={monthData}
                data={dataMonth}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardHeader
