import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import { useGeoFetch } from '../../components/GeoProvider'
import GeoProvider from '../../components/GeoProvider'
import Map from '../../components/Map'
import EmptyMap from '../../components/Map/emptyMap'

import colorAxisCasesWeekly from '../Covid-19/Covid19Map/colorAxisCasesWeekly'
import colorAxisCasesWeeklyHrr from '../Covid-19/Covid19Map/colorAxisCasesWeeklyHrr'

import HRRDefaults from './HRRDefaults'
import styles from './HrrMap.module.scss'

const HrrMap = ({hrrdata, staticStateJson}) => {
  const history = useNavigate()

  const today = dayjs().day(-7).format('YYYY-MM-DD') //Added by Avanish
  const weekEndData = dayjs().day(-1).format('YYYY-MM-DD')

  
  const hrrenddate = dayjs().day(-4).format('YYYY-MM-DD')
  const mapOptions = {
    value: 'hospital_admissions',
    label: 'Weekly Hospitalizations',
    parent: 'Covid Hospitalization',
    toolTip: null,
    mapTitle: null,
    mapSubTitle: null,
    colorAxis: colorAxisCasesWeekly,
    countyColorAxis: colorAxisCasesWeeklyHrr,
    legend: {
      enabled: false,
      title: {
        text: 'Weekly Hospitalizations',
      },
    },
  }

  const [states, setStates] = useState(undefined)
 
  // State Data
  const { data: statesTodayData, error: stateTodayDataError } = useGeoFetch(
    hrrenddate,
    hrrenddate,
    'covid/Hospitalization',
    'hrr'
  )
  var hrrdate
  useEffect(() => {
    if (statesTodayData !== undefined) {      
      setStates(statesTodayData.response_data)
      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statesTodayData])
  var graphOptions = null
  if (states) {    
    hrrdate = Object.keys(states)[0]
    var mapHRRData = null
    if (states.geo_id === undefined) {
      const hrrDataForDisplayDate = Object.entries(states[hrrdate])
      mapHRRData = hrrDataForDisplayDate.map((county) => {
        return {
          value: county[1][mapOptions.value],
          code: county[0].toString(),
          identifier: county[0].toString(),
          ...county[1],
        }
      })
    } else {    
      const hrrDefaults = {}
      hrrDefaults[hrrdate] = HRRDefaults
      const hrrDataForDisplayDate = Object.entries(hrrDefaults[hrrdate])
      mapHRRData = hrrDataForDisplayDate.map((county) => {
        return {
          value: county[1][mapOptions.value],
          code: county[0].toString(),
          identifier: county[0].toString(),
          ...county[1],
        }
      })
    }
    graphOptions = {
      title: {
        text: null,
      },
      subtitle: {
        text: mapOptions.mapSubTitle,
      },
      tooltip: {
        enabled: false,
      },
      chart: {
        backgroundColor: 'none',
        displayErrors: false,
      },
      legend: mapOptions.legend,

      credits: {
        enabled: false,
      },
      colorAxis: mapOptions.countyColorAxis,

      series: [
        {
          mapData: hrrdata,
          data: mapHRRData,
          joinBy: ['HRRNUM', 'geo_id'],
          borderColor: 'gray',
          borderWidth: 1,
          states: {
            select: {
              color: null,
              borderColor: 'gray',
            },
            hover: {
              borderColor: 'gray',
            },
          },
          shadow: false,
          cursor: 'pointer',
        },
      ],
      plotOptions: {
        map: {
          enableMouseTracking: false,
        },
      },
      mapline: {
        showInLegend: false,
        enableMouseTracking: false,
      },
    }
  }
  if (stateTodayDataError) {
    const hrrDefaults = {}
    hrrDefaults[today] = HRRDefaults
    return (
      <GeoProvider startDate={today} endDate={today} displayDate={weekEndData}>
        <Map
          toolTipFormatter={null}
          activeCbsasData={null}
          statesData={hrrDefaults}
          cbsaData={null}
          mapOptions={mapOptions}
          enableMapNavigation={false}
          enableTooltip={false}
          enableMouseTracking={false}
          mapHeight={'40vh'}
          mapType="COVIDDASHBOARDMAP"
        />
      </GeoProvider>
    )
  }

  return (
    <GeoProvider startDate={today} endDate={today} displayDate={weekEndData}>
      {states ? (
        <div
          className={styles.base}
          onClick={() => {
            history('/forecasts/hrrmap')
          }}
        >
         
          <HighchartsReact
            containerProps={{
              style: {
                height: '35vh',
                // width: '40vh',
                position: 'relative',
                display: 'flex',
                // marginLeft: '20vh',
              },
            }}
            constructorType="mapChart"
            highcharts={Highcharts}
            options={graphOptions}
            allowChartUpdate
            updateArgs={[true, true, false]}
          />
        </div>
      ) : (
        <EmptyMap stateJson={staticStateJson} mapHeight={'40vh'} />
      )}
    </GeoProvider>
  )
}

export default HrrMap
