import React,{useEffect} from 'react'
import { useNavigate } from 'react-router-dom'

import Content from '../../../components/Content'
import Layout from '../../../components/Layout'
import styles from './Dashboard.module.scss'
import { useAuthState } from '../../../components/AuthProvider'
import activityLog from '../../../utils/activityLog'
import DashboardHeader from './DashboardHeader'

const Dashboard = () => {
  const history = useNavigate()

  const { user } = useAuthState()

  var logObject = {
    dashboard_url: window.location.href,
    dashboard_name: 'Disease Prediction Dashboard',
    indicator_name: 'Disease Prediction Dashboard',
    geo_name: '',
    geo_type: '',
    user_name: user.username,
    email: user.email,
  }
  useEffect(() => {
    activityLog(logObject)
  }, [1])
  var data = []
  return (
    <Layout>
      <Content>
        <DashboardHeader />
        <div className={styles.base}>
          <div className={styles.covidPanel}>
            <div className={styles.dashboardCard}>
              <div className={styles.dashboardCardHeader}>
                <div
                  style={{
                    display: 'inline-flex',
                    width: '50%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    fontWeight: '600',
                    justifyContent: 'flex-start',
                  }}
                >
                  <span className={styles.mapName}>Vulnerability</span>
                </div>
                <div
                  style={{
                    display: 'inline-flex',
                    width: '50%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    fontSize: '14px',
                    fontWeight: '200',
                    justifyContent: 'flex-end',
                  }}
                >
                  <a
                    href=""
                    className={styles.btn}
                    onClick={() => {
                      history(`/DiseasePrediction/Vulnerability`)
                    }}
                  >
                    View More
                  </a>
                </div>
              </div>
              <div className={styles.descriptionDiv}>
                <p>
                  The vulnerability index uses machine learning to infer
                  individual patient vulnerability to adverse health outcomes
                  related to COVID-19 complications, such as intubation. The
                  comprehensive modelling considers comorbidities, immunization
                  status, demographics, and social determinants of health.
                  Vulnerability indices can also be applied to a wide range of
                  diseases, and can serve as complements to enhance existing
                  medical burden prediction, or can operate as stand-alone
                  metrics.
                </p>
              </div>
              <div className={styles.sourceDiv}>
                <span className={styles.graphSource}>
                  Source : <span>Medical and Pharmacy Claims Data</span>
                </span>
                <br></br>
                <span className={styles.graphSource}>
                  Last Update : <span>April 30, 2022</span>
                </span>
              </div>
            </div>
          </div>
          <div className={styles.hrrPanel}>
            <div className={styles.dashboardCard}>
              <div className={styles.dashboardCardHeader}>
                <div
                  style={{
                    display: 'inline-flex',
                    width: '50%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    fontWeight: '600',
                    justifyContent: 'flex-start',
                  }}
                >
                  <span className={styles.mapName}>Rare Disease</span>
                </div>
                <div
                  style={{
                    display: 'inline-flex',
                    width: '50%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    fontSize: '14px',
                    fontWeight: '200',
                    justifyContent: 'flex-end',
                  }}
                >
                  <a
                    href=""
                    className={styles.btn}
                    onClick={() => {
                      history(`/DiseasePrediction/RareDisease`)
                    }}
                  >
                    View More
                  </a>
                </div>
              </div>
              <div className={styles.descriptionDiv}>
                <p>
                  Machine learning is used to build predictive models that
                  identify undiagnosed rare disease patients, understand the
                  impact of treatments and therapy on patient outcomes, and
                  stratify rare disease patients by similarities. The models
                  also provide opportunity to prevent patient suffering and
                  intervention costs by infer the probability of patient
                  hospitalization up to weeks in advance.
                </p>
              </div>
              <div
                style={{ position: 'absolute', bottom: '10px' }}
                className={styles.sourceDiv}
              >
                <span className={styles.graphSource}>
                  Source : <span>Local Population Health Care Data</span>
                </span>
                <br></br>
                <span className={styles.graphSource}>
                  Last Update : <span>March 4, 2022</span>
                </span>
              </div>
            </div>
          </div>

          <div className={styles.fluPanel}>
            {' '}
            <div className={styles.dashboardCard}>
              <div className={styles.dashboardCardHeader}>
                <div
                  style={{
                    display: 'inline-flex',
                    width: '50%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    fontWeight: '600',
                    justifyContent: 'flex-start',
                  }}
                >
                  <span className={styles.mapName}>
                    Cardiac Risk Calculator
                  </span>
                </div>
                <div
                  style={{
                    display: 'inline-flex',
                    width: '50%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    fontSize: '14px',
                    fontWeight: '200',
                    justifyContent: 'flex-end',
                  }}
                >
                  <a
                    href=""
                    className={styles.btn}
                    onClick={() => {
                      history(`/DiseasePrediction/RiskCalculator`)
                    }}
                  >
                    View More
                  </a>
                </div>
              </div>
              <div className={styles.descriptionDiv}>
                <p>
                  The cardiac risk calculator uses machine learning to assess
                  individual patient risk of severe cardiac conditions including
                  heart attack and stroke. The model uses extensive patient
                  medical history data consisting of medications, pre-existing
                  conditions, and procedures to determine the risk of an adverse
                  event.
                </p>
              </div>
              <div
                style={{ position: 'absolute', bottom: '10px' }}
                className={styles.sourceDiv}
              >
                <span className={styles.graphSource}>
                  Source : <span>Local Population Health Care Data</span>
                </span>
                <br></br>
                <span className={styles.graphSource}>
                  Last Update : <span>NA</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </Content>
    </Layout>
  )
}

export default Dashboard
