import React, { useEffect, useState } from 'react'

import DefaultBreadcrumbs from '../../../../components/Breadcrumbs/DefaultBreadcrumbs'
import Spinner from '../../../../components/Loader/Spinner'
import Content from '../../../../components/Content'
import Layout from '../../../../components/Layout'
import Flex from '../../../../components/Flex'

import AddSubscriptionForm from './subscriptionForm'

import handleResponse from '../../../../utils/handleFetchResponse'

import styles from './addEditSubscription.module.scss'

const AddSubscription = () => {
  //.Hi
  const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL
  const userApiUrl = `${baseUrl}/admin/users`
  const activeCbsasFlu = `${baseUrl}/Subscription/fluactivecbsa`
  const activeCbsasCovid = `${baseUrl}/Subscription/covidactivecbsa`

  const [isLoading, setIsLoading] = useState(false)
  const [hasLoaded, setHasLoaded] = useState(false)
  const [activeFluCbsas, setActiveFluCbsas] = useState([])
  const [activeCovidCbsas, setActiveCovidCbsas] = useState([])
  //var token = window.localStorage.getItem('access_token')
  
  useEffect(() => {
    const getListOfActiveCbsasFlu = async () => {
      fetch(`${activeCbsasFlu}`,{
        method: 'get',
        headers: {
          'Content-Type': 'application/json',        
          //id_token: token,
        }
      }
      )
        .then((res) => handleResponse(res))
        .then((response) => {          
          setActiveFluCbsas(Object.values(response))
        })
    }

    const getListOfActiveCbsasCovid = async () => {
      fetch(`${activeCbsasCovid}`)
        .then((res) => handleResponse(res))
        .then((response) => {          
          setActiveCovidCbsas(Object.values(response))
        })
    }

    if (!hasLoaded) {
      setIsLoading(true)

      Promise.all([
        getListOfActiveCbsasFlu(),
        getListOfActiveCbsasCovid(),
      ]).then(() => {
        setIsLoading(false)
        setHasLoaded(true)
      })
    }
  }, [
    userApiUrl,
    setIsLoading,
    setHasLoaded,
    hasLoaded,
    activeCbsasCovid,
    activeCbsasFlu,
  ])
  const [getCbsaJsonFile, setCbsaJsonFile] = useState();
  useEffect(() => {
    let url = `${window.location.origin}/cb_2018_us_cbsa_20m_wkid102004.json`
    fetch(url)
      .then(function (res) {       
        return res.json();
      })
      .then(function (data) {
       setCbsaJsonFile(data);
      })
      .catch(function (err) {
        console.log(err, " error file load cb_2018_us_cbsa_20m_wkid102004 ");
      });
  }, [1]);
  return (
  
        <div className={styles.base} >
          <div>
            {/* <DefaultBreadcrumbs /> */}
            <div className={styles.content}>
              {/* <h1>Add Subscriptions</h1>
              <p>
                Enter Subscription details, Emails and SMS will be sent out
                every Monday.
              </p> */}
            </div>
          </div>
          <div className={styles.body}>
            <Flex gutter={3}>
              <Flex.Item span={12}>
                {isLoading  ? (
                  <Spinner />
                ) : hasLoaded && getCbsaJsonFile? (
                  <AddSubscriptionForm
                    activeCbsas={{
                      FLU: activeFluCbsas,
                      'COVID-19': activeCovidCbsas,
                    }}
                    cbsaJson={getCbsaJsonFile}
                  />
                ) : null}
              </Flex.Item>
            </Flex>
          </div>
        </div>
     
  )
}

export default AddSubscription
