import React, { useEffect } from 'react'
import { useAuthState } from '.'
import { useLocation, useNavigate } from 'react-router-dom'
import { USER_LOGIN_REDIRECT } from '../../utils/constants'
import Layout from '../Layout'
import Container from '../Container'
import NoAccess from '../../pages/NoAccess'

const RequireAuth = ({
  roles = ['Basic', 'Power User', 'Admin'],
  children,
}) => {
  const { isAuthenticated, user } = useAuthState()
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (!isAuthenticated) {
      window.localStorage.setItem(
        USER_LOGIN_REDIRECT,
        JSON.stringify({ location })
      )
      navigate('/')
    }
  }, [])

  if (isAuthenticated) {
    const isAuthorized = roles.includes(user.role)
    if (isAuthorized) {
      return <>{children}</>
    }
    return <NoAccess />
  }

  return null
}

export default RequireAuth
