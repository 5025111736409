import moment from 'moment'


const toolTipFormatter = (ordinate, xAxisLable) => {
  let toolTipHTML = ''
  let epiweek = ordinate.x
  if (xAxisLable === 'epiWeek') {
    toolTipHTML = `<div style="border:1px solid #CBCCCD;background-color:white;padding-top:16px;">`
    toolTipHTML += `<h4 style="color: #000; font-size:14px; padding-left: 10px; ">
        Epiweek ${ordinate.x}</h4>`
  } else {
    const date = moment(ordinate.x).format('MMM D, YYYY')
    toolTipHTML = `<h4 style="background-color: white; color: #black; padding: 10px; ">
        ${date} / Epiweek ${epiweek}</h4>`
  }
  const date = moment(ordinate.x).format('MMM D, YYYY')
  toolTipHTML += `<h4 style=" color: #000; font-size:12px; padding: 10px; ">
        ${date} : ${ordinate.y}</h4>`
  toolTipHTML += `</div>`

  return toolTipHTML
}
const toolTipFormatterDateWeek = (ordinate, data) => {
  const currentWeekMinus3Weeks = new Date(19)
  currentWeekMinus3Weeks.setDate(currentWeekMinus3Weeks.getDate() - 21)
  let toolTipHTML = ''
  let epiweek = ordinate.x
  toolTipHTML = `<div style="background-color: #FFFFFF;color:#4B4D4F;  padding: 16px;border: 1px  #CBCCCD;
  border-radius: 4px; ">
   <span style="font-family: 'Optum Sans', sans-serif;
   font-style: normal;
   font-weight: 700;
   font-size: 14px;
   line-height: 18px;">
     Epiweek ${epiweek}</span>`
     var showprediction=true
  ordinate.points.forEach((point) => {
    showprediction=(point.series.name.toLocaleString().toLowerCase()==="prediction intervals"?true:false)
    let weekindex = 0
    let legendSymbol = ''

    
    let isActual=
      (moment(currentWeekMinus3Weeks).format('MMM DD,YYYY') !==
      moment(point.series.data[weekindex].week_ending).format('MMM DD,YYYY'))
    if (point.series.legendSymbol !== undefined) {
      let series = point.series
      legendSymbol =
        "<svg width='16' height='16'>" +
        series.legendSymbol.element.outerHTML +
        point.series.legendSymbol.element.previousElementSibling.outerHTML +
        '</svg>'
    }
    if ('high' in point.point && isActual) {
      toolTipHTML += `<h6 style="font-family: 'Optum Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;"><span style="padding-right:8px;">${legendSymbol}</span>${moment(
        point.series.data[weekindex].week_ending
      ).format('MMM DD,YYYY')}: <b> ${point.point.low.toLocaleString(
        undefined,
        {
          minimumFractionDigits: 0,
        }
      )} - ${point.point.high.toLocaleString(undefined, {
        minimumFractionDigits: 0,
      })}</b></h6></div>`
    } else if (
      point.series.name.toLocaleString().toLowerCase() !== 'baseline' &&
      isActual
    ) {
      toolTipHTML += `<h6 style="font-family: 'Optum Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;"><span style="padding-right:8px;">${legendSymbol}</span>${moment(
        point.series.data[weekindex].week_ending
      ).format('MMM DD,YYYY')}: <b>${point.y.toLocaleString(undefined, {
        minimumFractionDigits: 0,
      })}</b></h6>`
    } else if (
      point.series.name.toLocaleString().toLowerCase() !== 'forecast' &&
      point.series.name.toLocaleString().toLowerCase() !==
        'prediction intervals'
    ) {
      toolTipHTML += `<h6 style="font-family: 'Optum Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;"><span style="padding-right:8px;">${legendSymbol}</span>
      ${
        point.series.name.toLocaleString().toLowerCase() === 'baseline'
          ? 'Baseline'
          : moment(point.series.data[weekindex].week_ending).format(
              'MMM DD,YYYY'
            )
      }: <b>${point.y.toLocaleString(undefined, {
        minimumFractionDigits: 0,
      })}</b></h6>`
    }
  })

  return toolTipHTML
}
const toolTipFormatterDateWeekNew = (ordinate, data) => {
  const currentWeekMinus3Weeks = new Date(19)
  currentWeekMinus3Weeks.setDate(currentWeekMinus3Weeks.getDate() - 21)
  let toolTipHTML = ''
  let epiweek = ordinate.x
  toolTipHTML = `<div style="background-color: #FFFFFF;color:#4B4D4F;  padding: 16px;border: 1px  #CBCCCD;
  border-radius: 4px; ">
   <span style="font-family: 'Optum Sans', sans-serif;
   font-style: normal;
   font-weight: 700;
   font-size: 14px;
   line-height: 18px;">
     Epiweek ${epiweek}</span>`
     var showprediction=true
  ordinate.points.forEach((point) => {
    showprediction=(point.series.name.toLocaleString().toLowerCase()==="prediction intervals"?true:false)
    let weekindex = 0
    let legendSymbol = ''

    
    let isActual=true
  
    if (point.series.legendSymbol !== undefined) {
      let series = point.series
      legendSymbol =
        "<svg width='16' height='16'>" +
        series.legendSymbol.element.outerHTML +
        point.series.legendSymbol.element.previousElementSibling.outerHTML +
        '</svg>'
    }
    if ('high' in point.point && isActual) {
      toolTipHTML += `<h6 style="font-family: 'Optum Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;"><span style="padding-right:8px;">${legendSymbol}</span>${moment(
        point.series.data[weekindex].week_ending
      ).format('MMM DD,YYYY')}: <b> ${point.point.low.toLocaleString(
        undefined,
        {
          minimumFractionDigits: 0,
        }
      )} - ${point.point.high.toLocaleString(undefined, {
        minimumFractionDigits: 0,
      })}</b></h6></div>`
    } else if (
      point.series.name.toLocaleString().toLowerCase() !== 'baseline' &&
      isActual
    ) {
      toolTipHTML += `<h6 style="font-family: 'Optum Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;"><span style="padding-right:8px;">${legendSymbol}</span>${moment(
        point.series.data[weekindex].week_ending
      ).format('MMM DD,YYYY')}: <b>${point.y.toLocaleString(undefined, {
        minimumFractionDigits: 0,
      })}</b></h6>`
    } else if (
      point.series.name.toLocaleString().toLowerCase() !== 'forecast' &&
      point.series.name.toLocaleString().toLowerCase() !==
        'prediction intervals'
    ) {
      toolTipHTML += `<h6 style="font-family: 'Optum Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;"><span style="padding-right:8px;">${legendSymbol}</span>
      ${
        point.series.name.toLocaleString().toLowerCase() === 'baseline'
          ? 'Baseline'
          : moment(point.series.data[weekindex].week_ending).format(
              'MMM DD,YYYY'
            )
      }: <b>${point.y.toLocaleString(undefined, {
        minimumFractionDigits: 0,
      })}</b></h6>`
    }
  })

  return toolTipHTML
}
const toolTipFormatterSurveillance = (ordinate) => {
  let toolTipHTML = ''
  let epiweek = ordinate.x
  toolTipHTML = `<div style="background-color: #FFFFFF;color:#4B4D4F;  padding: 16px;border: 1px  #CBCCCD;
  border-radius: 4px; ">
   <span style="font-family: 'Optum Sans', sans-serif;
   font-style: normal;
   font-weight: 700;
   font-size: 14px;
   line-height: 18px;">
     Epiweek ${epiweek}</span>
     `
  ordinate.points.forEach((point) => {
    
    let legendSymbol = ''
    if (point.series.legendSymbol !== undefined) {
      
      let series = point.series
      legendSymbol =
        "<svg width='16' height='16'>" +
        series.legendSymbol.element.outerHTML +
        point.series.legendSymbol.element.previousElementSibling.outerHTML +
        "</svg>"
       
    }
      
      toolTipHTML += `<h6 style="font-family: 'Optum Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;padding-top:10px">
      
      <span style="">${legendSymbol} ${point.series.name}
      (${moment(
        point.point.week_ending
      ).format('MMM DD,YYYY')})</span>
      : <b>${point.y.toLocaleString(undefined, {
        minimumFractionDigits: 0,
      })}</b></h6>`
    
  })
  toolTipHTML+=`</div>`
  return toolTipHTML
}

function epiweekToolTip() {
  return toolTipFormatter(this, 'epiWeek')
}

function dateToolTip() {
  return toolTipFormatter(this)
}
function dateWeekToolTip() {
  return toolTipFormatterDateWeek(this)
}
function dateWeekToolTipNew() {
  return toolTipFormatterDateWeekNew(this)
}
function surveillanceCompareToolTip() {
  return toolTipFormatterSurveillance(this)
}

export { epiweekToolTip, dateToolTip, dateWeekToolTip,dateWeekToolTipNew,surveillanceCompareToolTip }
