
import dedupList from '../../utils/dedupeList'
function getStateOptions(enableDataLabels, stateJson) {
  
  return {
    borderColor: 'gray',
    borderWidth: 1,
    mapData: stateJson,
    name: 'Infections by State',
    joinBy: ['hc-a2', 'state'],
    animation: false,
    cursor: 'pointer',
    allowPointSelect: true,
    states: {
      select: {
        color: null,
        borderColor: 'gray',
      },
      hover: {
        borderColor: 'lightgray',
      },
    },
    dataLabels: {
      enabled: enableDataLabels,
      format: '{point.hc-a2}',
    },
  }
}

function getCbsaOptions(activeCbsas, cbsaJson) {
  let filteredFeats = []
  cbsaJson.features.forEach((feat) => {
    activeCbsas.includes(feat.properties.geoid)
    if (activeCbsas.includes(feat.properties.geoid)) {
      filteredFeats.push(feat)
    }
  })

  cbsaJson.features = filteredFeats

  return {
    borderColor: 'gray',
    borderWidth: 1,
    mapData: cbsaJson,
    name: 'Infection rate',
    dataLabels: {
      enabled: false,
      format: '{point.name}',
    },
    joinBy: 'cbsa',
    animation: false,
    cursor: 'pointer',
    allowPointSelect: true,
    states: {
      select: {
        color: null,
        borderColor: 'gray',
      },
      hover: {
        borderColor: 'gray',
      },
    },
  }
}
function getMSAOptions(cbsaJson) {
  return {
    borderColor: 'gray',
    borderWidth: 1,
    mapData: cbsaJson,
    name: 'Infections by MSA',
    joinBy: ['cbsa', 'cbsa'],
    animation: false,
    cursor: 'pointer',
    allowPointSelect: true,
    states: {
      select: {
        color: null,
        borderColor: 'gray',
      },
      hover: {
        borderColor: 'lightgray',
      },
    },
    dataLabels: {
      enabled: true,
      format: '{point.hc-a2}',
    },
  }
}
function getMapOptions(
  colorAxis,
  legend,
  toolTipFormatter,
  activeCbsasData,
  cbsaData,
  stateData,
  msaData,
  history,
  titleText = null,
  subtitleText = null,
  showStateLabels = true, //false, //Change by Avanish to show names for Idp revamp
  enableTooltip = true,
  enableMapNavigation = true,
  enableMouseTracking = true,
  mapType,
  enableButtons,
  stateJson,
  cbsaJson,
) {
  let cbsaOptions = {}
  let stateOptions = {}
  let msaOptions = {}
  if (cbsaData === null) {
    cbsaOptions.visible = false // = {}
  } else {

    cbsaOptions = getCbsaOptions(activeCbsasData, cbsaJson)
    cbsaOptions.visible = true
    cbsaOptions.data = dedupList(cbsaData)
  }
  if (msaData === null) {
    msaOptions.visible = false // = {}
  } else {
    msaOptions = getMSAOptions(cbsaJson)
    msaOptions.visible = true
    msaOptions.data = dedupList(msaData)
  }
  if (stateData === null) {
    stateOptions.visible = false // = {}
  } else {
    stateOptions = getStateOptions(showStateLabels, stateJson)
    stateOptions.visible = true
    stateOptions.data = dedupList(stateData)
  }
  return {
    title: {
      text: titleText,
      style: {
        color: '#424242',
        fontWeight: '700',
        //fontfamily: 'graphik',
        fontsize: '16px',
      },
      align:
        mapType.toUpperCase() === 'CIACCURACY' || 'COVIDACC' ? 'center' : 'left',
    },
    subtitle: {
      text: subtitleText,
    },
    chart: {
      backgroundColor: 'none',
      displayErrors: false,
    },
    colorAxis: colorAxis,
    legend: legend,
    series: [stateOptions, cbsaOptions, msaOptions],
    mapNavigation: {
      enabled: enableMapNavigation,
      enableMouseWheelZoom: false,
      enableButtons: enableButtons,
      buttonOptions: {
        verticalAlign: 'bottom',
        align: 'right',
      },
    },
    tooltip: {
      enabled: enableTooltip,
      useHTML: true,
      padding: 0,
      borderRadius: 10,
      formatter: toolTipFormatter,
      outside:true
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      map: {
        enableMouseTracking: enableMouseTracking,
        point: {
          events: {
            select: function () {
              // handleClick({
              //   clicked: true,
              //   state: this.options.geo_name,
              //   stateCode: this.options.state,
              //   stateid: this.options.state,
              // })
              let hash = undefined
              if (mapType !== undefined && mapType === 'CIACCURACY') {
                if ('state' in this.options) {
                  hash = `state-${this.options.state}`
                  history({ hash: hash })
                } else if ('cbsa' in this.options) {
                  hash = `msa-${this.options.cbsa}`
                  history({ hash: hash })
                }
              } else if (mapType !== undefined && mapType === 'FLUMAP') {
                if ('state' in this.options) {
                  hash = `state-${this.options.state}`
                  history({ hash: hash })
                } else if ('cbsa' in this.options) {
                  hash = `metro-${this.options.cbsa}`
                  history({ hash: hash })
                }
              } else if (mapType !== undefined && mapType === 'VUL') {
                if ('state' in this.options) {
                  if (
                    this.options.projected_Pct === 0 ||
                    this.options.projected_Pct === undefined
                  ) {
                  } else {
                    hash = `state-${this.options.stateId}`
                    history({ hash: hash })
                  }
                }
              } else if (mapType !== undefined && mapType === 'COVERAGEMAP')
              {

              }
              else {
                if ('state' in this.options) {
                  hash = `state-${this.options.state}`
                  history({ hash: hash })
                } else if ('cbsa' in this.options) {
                  hash = `msa-${this.options.cbsa}`
                  history({ hash: hash })
                }
              }
              // if (mapType != undefined && mapType == 'CIACCURACY') {
              //   if (this.options.geo_type.toUpperCase() == 'STATE') {
              //     // hash = `-state-${this.options.state}`
              //     history(
              //       `/about/model#accuracy-state-${this.options.state}`
              //     )
              //   } else if (this.options.geo_type.toUpperCase() == 'MSA') {
              //     // hash = `msa-${this.options.cbsa}`
              //     history(`/about/model#accuracy-msa-${this.options.cbsa}`)
              //   } else if (this.options.geo_type.toUpperCase() == 'CITY') {
              //     // hash = `-metro-${this.options.cbsa}`
              //     history(
              //       `/about/model#accuracy-metro-${this.options.cbsa}`
              //     )
              //   }
              // } else {
              //   if ('state' in this.options) {
              //     hash = `state-${this.options.state}`
              //     history({ hash: hash })
              //   } else if ('cbsa' in this.options) {
              //     hash = `msa-${this.options.cbsa}`
              //     history({ hash: hash })
              //   }
              // }
              // if ('msa' in this.options) {
              //   hash = `msa-${this.options.cbsa}`
              //   history({ hash: hash })
              // }

              return false
            },
            unselect: function () {
              //TODO: check if we are unselecting what is already selected and remove it form the hash
              // history({ hash: null })
            },
          },
        },
      },
    },
  }
}

export default getMapOptions
