import React,{ useState } from 'react'
import LinkButton from '../../components/LinkButton'
import styles from './SideDataTable.module.scss'
import getVectorImage from '../../utils/getVectorImage'
import ReactSwitch from 'react-switch'
import toTitleCase from '../../utils/toTitleCase'
import { useGeoState } from '../../components/GeoProvider'
import ExpandArrowLeft from '../../assets/images/icon/arrow_expand_left.png'
import SearchIcon from '../../assets/images/icon/search.png'

const SideDataTable = ({stateData,lobOptions,posOptions }) => {
  
  const inputRef = React.useRef(false)
  let isCancelled = React.useRef(false)
  const [btnSearchLocation, setBtnSearchLocation] = React.useState(false)
  const { showUHCCoverage, setUHCCoverage,showFullTable,setIsChecked, 
    setShowFullTable,regionSelected,searchFilter,setSearchFilter,lobSelected,posSelected } = useGeoState()
 const LobData= ({
 data_val,lobSelected
}) => {
  switch(lobSelected)
  {
    case 1:
      return<> <td style={{borderRight:'2px solid #002677'}}>{data_val.mcd===null?'NA':parseFloat(data_val.mcd).toFixed(3)}</td></> 
    case 2:
      return<> <td style={{borderRight:'2px solid #002677'}}>{data_val.mcr===null?'NA':parseFloat(data_val.mcr).toFixed(3)}</td></>
    case 3:
      return<><td style={{borderRight:'2px solid #002677'}}>{data_val.com===null?'NA':parseFloat(data_val.com).toFixed(3)}</td></>
    case 4:
      return<><td style={{borderRight:'2px solid #002677'}}>{data_val.lob_unknown===null?'NA':parseFloat(data_val.lob_unknown).toFixed(3)}</td></>
    default:
      return<>
        <td>{data_val.mcd===null?'NA':parseFloat(data_val.mcd).toFixed(3)}</td>
  <td>{data_val.mcr===null?'NA':parseFloat(data_val.mcr).toFixed(3)}</td>
  <td >{data_val.com===null?'NA':parseFloat(data_val.com).toFixed(3)}</td>
  <td style={{borderRight:'2px solid #002677'}}>{data_val.lob_unknown===null?'NA':parseFloat(data_val.lob_unknown).toFixed(3)}</td>
      </>
    }
}
const PosData= ({
  data_val,posSelected
 }) => {
   switch(posSelected)
   {
     case 1:
       return<>  <td>{data_val.emergency_room===null?'NA':parseFloat(data_val.emergency_room).toFixed(3)}</td></> 
     case 2:
       return<><td>{data_val.home===null?'NA':parseFloat(data_val.home).toFixed(3)}</td></>
     case 3:
       return<><td >{data_val.laboratory===null?'NA':parseFloat(data_val.laboratory).toFixed(3)}</td></>
     case 4:
       return<><td >{data_val.living_facility===null?'NA':parseFloat(data_val.living_facility).toFixed(3)}</td></>
      case 5:
        return<><td >{data_val.mobile_unit===null?'NA':parseFloat(data_val.mobile_unit).toFixed(3)}</td></>
      case 6:
        return<><td >{data_val.pharmacy===null?'NA':parseFloat(data_val.pharmacy).toFixed(3)}</td></>
      case 7:
        return<><td >{data_val.telehealth===null?'NA':parseFloat(data_val.telehealth).toFixed(3)}</td></>
      case 8:
        return<><td >{data_val.urgent_care===null?'NA':parseFloat(data_val.urgent_care).toFixed(3)}</td></>
      case 9:
        return<><td >{data_val.other===null?'NA':parseFloat(data_val.other).toFixed(3)}</td></>
      case 10:
        return<><td >{data_val.inpatient===null?'NA':parseFloat(data_val.inpatient).toFixed(3)}</td></>
      case 11:
        return<><td >{data_val.outpatient===null?'NA':parseFloat(data_val.outpatient).toFixed(3)}</td></>
      case 99:
        return<><td >{data_val.unknown===null?'NA':parseFloat(data_val.unknown).toFixed(3)}</td></>
      default:
       return<>
         <td>{data_val.emergency_room===null?'NA':parseFloat(data_val.emergency_room).toFixed(3)}</td>
    <td>{data_val.home===null?'NA':parseFloat(data_val.home).toFixed(3)}</td>
    <td>{data_val.laboratory===null?'NA':parseFloat(data_val.laboratory).toFixed(3)}</td>
    <td>{data_val.living_facility===null?'NA':parseFloat(data_val.living_facility).toFixed(3)}</td>
    <td>{data_val.mobile_unit===null?'NA':parseFloat(data_val.mobile_unit).toFixed(3)}</td>
    <td>{data_val.pharmacy===null?'NA':parseFloat(data_val.pharmacy).toFixed(3)}</td>
    <td>{data_val.telehealth===null?'NA':parseFloat(data_val.telehealth).toFixed(3)}</td>
    <td>{data_val.urgent_care===null?'NA':parseFloat(data_val.urgent_care).toFixed(3)}</td>
    <td>{data_val.other===null?'NA':parseFloat(data_val.other).toFixed(3)}</td>
    <td>{data_val.inpatient===null?'NA':parseFloat(data_val.inpatient).toFixed(3)}</td>
    <td>{data_val.outpatient===null?'NA':parseFloat(data_val.outpatient).toFixed(3)}</td>
    <td>{data_val.unknown===null?'NA':parseFloat(data_val.unknown).toFixed(3)}</td>
       </>
     }
 }
  const handleChangeCoverage = (val) => {
    setUHCCoverage(val)
  }
  const handleChange = (event) => {
    setSearchFilter(event.target.value)
  }
  const handleFullTableChange = () => {
    setShowFullTable(!showFullTable)
    setIsChecked(false)
  }
  const [getFilter, setfilter] = useState('down')
  const sortBy = (action) => {
    if (action === 'up') {
      setfilter('down')
      stateData.sort((a, b) => b.data_value - a.data_value)
    } else if (action === 'down') {
      setfilter('up')
      stateData.sort((a, b) => a.data_value - b.data_value)
    }
  }
  function showSearch() {
    isCancelled.current = true
    btnSearchLocation ? setBtnSearchLocation(false) : setBtnSearchLocation(true)
    btnSearchLocation ? setSearchFilter('') : (inputRef.current = false)
  }
 let lobs=(lobSelected===0?
 lobOptions.filter(({ data_id }) => data_id !==lobSelected ).map((lob)=>{
    return lob.data_value
 }):lobOptions.filter(({ data_id }) => data_id ===lobSelected ).map((lob)=>{
  return lob.data_value
}))

let poss=(posSelected===0?
  posOptions.filter(({ data_id }) => data_id !==posSelected ).map((pos)=>{
     return pos.data_value
  }):posOptions.filter(({ data_id }) => data_id ===posSelected ).map((pos)=>{
   return pos.data_value
 }))


  let filteredStateData = stateData
  if (searchFilter !== '') {
    // eslint-disable-next-line array-callback-return
    filteredStateData = stateData.filter((cbsa) => {
     
      if (
        cbsa.name &&
        cbsa.name.toLowerCase().includes(searchFilter.toLowerCase())
      ) {
        return cbsa
      }
    })
  }
  // eslint-disable-next-line array-callback-return
  filteredStateData = filteredStateData.filter((cbsa) => {
    if (cbsa.name && cbsa.name.length > 1) {
      return cbsa
    }
  })
 
  let fcount1=0
  return  (
    <div className={styles.base}>
      <div
          style={{
            marginTop: '16px',
            padding:'0px 16px 0px 10px'
          }}
        >
          <div style={{ display: 'flex' }}>
            <div
              style={{
                display: 'inline-flex',
                width: '70%',
              }}
            >
              <span>
                <h5
                  style={{
                    fontFamily: 'Optum Sans',
                    fontSize: '21px',
                    fontWeight: '600',
                    color: '#002677',
                  }}
                >
                  {regionSelected.toUpperCase()==='MSA'?'State & MSA':toTitleCase(regionSelected)}
                  
                </h5>
              </span>
            </div>
            <div  style={{
                display: 'inline-flex',
                width: '30%',
                flexDirection: 'row-reverse',
              }} >
              <img
                src={ExpandArrowLeft}
                alt="expand-right"
                onClick={handleFullTableChange}
                style={{ height: '20px', width: '20px', marginBottom: '-3px',cursor:'pointer' }}
              ></img>
              <span >
                 <img
                src={SearchIcon}
                onClick={showSearch}
                alt="expand-right"
                style={{ height: '24px', 
                width: '24px',
                marginRight:'10px',
                marginBottom: '-3px',
                cursor:'pointer' }}
              ></img>
              </span>
              </div>
          </div>
          <div>
          {btnSearchLocation ? (
            <input
            className={styles.searchInput}
              ref={inputRef}
              type="text"
              onChange={handleChange}
              placeholder={"Search "+toTitleCase(regionSelected)}
            />
          ) : null}
          </div>
        </div>
       
          
          <div
            style={{
              display: 'flex',
             
              justifyContent: 'flex-end',
              paddingBottom: '16px',
              paddingTop: '16px',
              paddingRight:'16px'
            }}
          >
            <span className={styles.showOnMap}>UHC Coverage</span>
            <ReactSwitch
              onColor="#196ECF"
              offColor="#7D7F81"
              height={20}
              width={40}
              uncheckedIcon={false}
              checkedIcon={false}
              handleDiameter={15}
              checked={showUHCCoverage}
              onChange={handleChangeCoverage}
            />
          </div>
      <div className={styles.maindivFormat}>
          <table>
            <thead>
              <tr>
                <th>Area Name</th>
                <th onClick={() => sortBy(getFilter)} style={{cursor:'pointer'}}> 
                <span style={{ marginRight: '5px' }}>
                {getVectorImage(getFilter)}
              </span>Incidence</th>
                <th style={{borderRight:'2px solid #002677'}}>Change</th>
                
              {lobSelected===0?<>
                <th >{lobs[0]}</th>
                <th >{lobs[1]}</th>
                <th>{lobs[2]}</th>
                <th style={{borderRight:'2px solid #002677'}}>{lobs[3]}</th>
              </>:
              lobs.map( (lob) => (
                <th style={{borderRight:'2px solid #002677'}}>{lob}</th>
              ))
              }

              {posSelected===0?poss.map( (pos) => (
                <th >{pos}</th>
              )):
              poss.map( (pos) => (
                <th style={{borderRight:'2px solid #002677'}}>{pos}</th>
              ))
              }
              </tr>
            </thead>
            <tbody style={{fontSize:'14px'}}>
            {
              filteredStateData.length === 0 ? (
                <tr>
                  <td style={{ textAlign: 'center' }} colSpan={5}>
                    No data to show
                  </td>
                </tr>
              ) : (
                filteredStateData.map((data_val) => {
      
    return(
    <tr style={{borderBottom:'1px solid #ddd'}}>
    <td>
    <LinkButton
          className={styles.btn}
        >
             {data_val.identifier} 
        </LinkButton>
    </td>
    <td>{data_val.data_value===null?'NA':parseFloat(data_val.data_value).toFixed(3)}</td>
    <td style={{borderRight:'2px solid #002677'}}>{data_val.istrue===true?getVectorImage('trendup'):getVectorImage('trenddown') } {parseFloat(data_val.change_actual_percentage).toFixed(2)}</td>
    <LobData data_val={data_val} lobSelected={lobSelected}></LobData>
    <PosData data_val={data_val} posSelected={posSelected}></PosData>
    </tr>
            )
   })
  )
   }
            </tbody>
          </table>
        </div>

    </div>
    
  ) 
}

export default SideDataTable
