import React from 'react'
import { useGeoState } from '../../components/GeoProvider'
import SideDataTable from './SideDataTable'



const SideFullTable = ({ stateData, chartRef,jsonData,lobOptions,posOptions}) => {

  const {
    displayDate
  } = useGeoState()
  const stateDataForDisplayDate = Object.entries(stateData[displayDate])
  const sideStatsDisplayDate=Object.entries(jsonData[displayDate])
  
  const mappedStateData = stateDataForDisplayDate.map((state) => {
    const stateName = state[1].geo_name
    let dd=sideStatsDisplayDate.find(function (p) {
      if(p[0] === state[1].geo_id)
      return p[1]
       
    })
  
    return { ...state[1],...dd[1], name: stateName, identifier: stateName }
  })


  let filteredStateData = mappedStateData



  return (
    <>
    <SideDataTable stateData={filteredStateData} lobOptions={lobOptions} posOptions={posOptions}  />
    
     </>
   
  )
}

export default SideFullTable
