import React from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './SearchPopover.module.scss'

const SearchPopoverMsaItems = React.forwardRef(
  (
    { criteria, onAdd, searchText, visible: isVisible, entity, activeMetros },
    ref
  ) => {
    const history = useNavigate()

    const filteredMetros = activeMetros.filter((metro) => {
      if (
        (metro.label &&
          metro.label.toLowerCase().includes(searchText.toLowerCase())) ||
        metro.value.toLowerCase().includes(searchText.toLowerCase())
      ) {
        return metro
      } else {
        return null
      }
    })
    const slicedFilteredMetros = filteredMetros.slice(0, 10)

    return (
      <>
        {slicedFilteredMetros.map((option) => (
          <li
            data-option
            key={option.value}
            onClick={() =>
              (window.location.href = `/${entity}MSADashboard/${option.geo_parent}/MSA/${option.value}`)
            }
            onKeyDown={(event) => {
              const { key, currentTarget } = event
              const next = key === 'ArrowDown'
              const prev = key === 'ArrowUp'

              if (!next && !prev) return

              event.preventDefault()

              const nextFocus =
                currentTarget[
                  next ? 'nextElementSibling' : 'previousElementSibling'
                ]

              if (nextFocus) nextFocus.focus()
            }}
            onKeyPress={({ key }) =>
              key === 'Enter' &&
              (window.location.href = `/${entity}MSADashboard/${option.geo_parent}/MSA/${option.value}`)
            }
            tabIndex={-1}
          >
            <span>
              {option.label} ({option.geo_parent}){' '}
              <span style={{ color: '#7D7F81', paddingLeft: '5px' }}> MSA</span>
            </span>
          </li>
        ))}
      </>
    )
  }
)
export default SearchPopoverMsaItems
