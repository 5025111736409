// https://www.npmjs.com/package/react-toastify#usage

import React from 'react'
import { ToastContainer, toast } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
import styles from './MessageProvider.module.scss'

export const MessageContext = React.createContext()

export const useMessageToast = () => {
  const context = React.useContext(MessageContext)
  if (!context) {
    throw new Error('useMessageToast must be used within a MessageProvider')
  }
  return context
}

const MessageProvider = ({ children = null }) => (
  <MessageContext.Provider value={toast}>
    {children}
    <ToastContainer toastClassName={styles.base} />
  </MessageContext.Provider>
)

export default MessageProvider
