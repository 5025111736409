import React, { useEffect, useState, useCallback } from 'react'
import axios from 'axios'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Link, useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import Icon from '../../../../components/Icon'
import Layout from '../../../../components/Layout'
import Flex from '../../../../components/Flex'
import Content from '../../../../components/Content'
import Button from '../../../../components/Button'
import ButtonList from '../../../../components/ButtonList'
import Breadcrumbs from '../../../../components/Breadcrumbs'
import { useMessageToast } from '../../../../components/MessageProvider'
import { useFetch } from '../../../../components/useFetch'
import { useAuthState } from '../../../../components/AuthProvider'
import Label from '../../../../components/Label'
import TextBox from '../../../../components/TextBox'
import Spinner from '../../../../components/Loader/Spinner'
import DropDownList from '../../../../components/DropDownList'

import styles from './AddEditUser.module.scss'

const AddEditUser = () => {
  const clientConfigApiDomain = process.env.REACT_APP_BACKEND_BASE_URL
  const userApiUrl = `${clientConfigApiDomain}/admin/users`
  const roleApiUrl = `${clientConfigApiDomain}/admin/roles`
  const phoneRegExp =
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/
  const { id } = useParams()
  const history = useNavigate()
  const { user } = useAuthState()
  const toast = useMessageToast()
  const [
    {
      data: addEditUserData,
      hasLoaded: addEditHasLoaded,
      hasError: addEditHasError,
    },
    addEditFetch,
  ] = useFetch()

  const [userData, setUserData] = useState([])
  const [rolesData, setRolesData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [hasLoaded, setHasLoaded] = useState(false)

  const fetchRoleData = useCallback(async () => {
    const response = await fetch(`${roleApiUrl}/`)
    const data = await response.json()
    setRolesData(data)
  }, [roleApiUrl])

  const fetchData = useCallback(async () => {
    const response = await fetch(`${userApiUrl}/${id}/`)
    const data = await response.json()
    setUserData(data)
  }, [id, userApiUrl])

  useEffect(() => {
    if (!hasLoaded && id !== undefined) {
      setIsLoading(true)
      Promise.all([fetchData(), fetchRoleData()]).then(() => {
        setIsLoading(false)
        setHasLoaded(true)
      })
    } else {
      setIsLoading(true)
      fetchRoleData().then(() => {
        setIsLoading(false)
        setHasLoaded(true)
      })
    }
  }, [id, fetchData, fetchRoleData, hasLoaded])

  useEffect(() => {
    if (id === undefined && addEditHasLoaded) {
      if (addEditHasLoaded) {
       
        toast.success(`User added successfully.`)
        history(`/admin/users`)
        //history(`/admin/users/${addEditUserData[0].userid}`)
      } else if (addEditHasError) {
        toast.error(`Failed to add user.`)
      }
    } else {
      if (addEditHasLoaded) {
        toast.success(`User updated successfully.`)
        history(`/admin/users/`)
       // history(`/admin/users/${addEditUserData[0].userid}`)
      } else if (addEditHasError) {
        toast.error(`Failed to update this user.`)
      }
    }
  }, [id, addEditUserData, addEditHasLoaded, addEditHasError, toast, history])

  let loadedBreadcrumbs = ''
  if (isLoading) {
    loadedBreadcrumbs = <Spinner />
  } else if (hasLoaded) {
    loadedBreadcrumbs = (
      <Breadcrumbs>
        <Breadcrumbs.Item render={() => <Link to="/">Home</Link>} />
        <Breadcrumbs.Item
          render={() => <Link to="/admin">Administration</Link>}
        />
        <Breadcrumbs.Item render={() => <Link to="/admin/users">Users</Link>} />
        {id ? (
          <>
            <Breadcrumbs.Item
              render={() => (
                <Link to={`/admin/users/${id}`}>
                  User: {userData.lastName}, {userData.firstName}
                </Link>
              )}
            />
            <Breadcrumbs.Item last render={() => 'Edit'}></Breadcrumbs.Item>
          </>
        ) : (
          <Breadcrumbs.Item last render={() => `Add User`} />
        )}
      </Breadcrumbs>
    )
  }

  const isEditVisible = (selectedUser) => {
    return user.role === 'Admin' || selectedUser !== 'Admin'
  }

  return (
    // <Layout>
    //   <Content>
    <div className={styles.base}>
      <div className={styles.header}>
        {/* {loadedBreadcrumbs} */}
        <div className={styles.content}>
          {id ? (
            <>
              <span>
                Edit User
                {/* : {userData.lastName}, {userData.firstName} */}
              </span>
              {/* <p>Edit User Details.</p> */}
            </>
          ) : (
            <>
              <div style={{display:'flex'}}>
                <span style={{fontSize:'18px', fontWeight:700}}>Add User</span> 
              <button
                noValidate
                style={{ marginLeft: '71%',cursor:'pointer' }}
                onClick={() => {
                  history(`/admin/users`)
                }}
              >
                <Icon type="remove" size={'1.5rem'} />
              </button>
              </div>
              {/* <p>Enter User Details.</p> */}
            </>
          )}
        </div>
      </div>
      <div className={styles.body}>
        <Flex gutter={3}>
          {isLoading ? (
            <Spinner />
          ) : hasLoaded ? (
            <Flex.Item span={12}>
              <div className={styles.base}>
                <div className={styles.details}>
                  <Formik
                    initialValues={{
                      id: userData.userId,
                      firstName: userData.firstName,
                      lastName: userData.lastName,
                      email: userData.email,
                      phone: userData.phone,
                      username: userData.username,
                      roleName: userData.roleName,
                      // createdBy: userData.createdBy,
                      updatedBy: userData.updatedBy,
                    }}
                    validationSchema={Yup.object().shape({
                      firstName: Yup.string()
                        .trim()
                        .required('First Name field is required.')
                        .min(
                          2,
                          'First Name name should be at-least two characters.'
                        )
                        .max(
                          100,
                          'First Name name should be max 100 characters long.'
                        ),
                      lastName: Yup.string()
                        .trim()
                        .required('Last Name field is required.')
                        .min(
                          2,
                          'Last Name should be at-least two characters.'
                        )
                        .max(
                          120,
                          'First Name should be max 120 characters long.'
                        ),
                      username: Yup.string()
                        .trim()
                        .required('Username is required.')
                        .min(
                          2,
                          'Username should be at-least two characters.'
                        )
                        .test(
                          'Unique Username',
                          'Username already in use',
                          function (value) {
                            if (value !== undefined && value.length >= 2) {
                              return new Promise((resolve, reject) => {
                                axios
                                  .get(`${userApiUrl}/?username=${value}`)
                                  .then((res) => {
                                    // if adding id will be undefined
                                    if (
                                      res.status === 200 &&
                                      res.data.length === 0
                                    ) {
                                      resolve(true)
                                    }
                                    // if editing id is not undefined
                                    if (
                                      res.status === 200 &&
                                      res.data.length === 1 &&
                                      parseInt(id) ===
                                      parseInt(res.data[0].userId)
                                    ) {
                                      resolve(true)
                                    }

                                    resolve(false)
                                  })
                              })
                            }
                          }
                        ),
                      email: Yup.string()
                        .trim()
                        .email('Email is Invalid')
                        .required('Email field is required.')
                        .max(
                          100,
                          'Email should be max 100 characters long.'
                        )
                        .test(
                          'Unique Email',
                          'Email already in use',
                          function (value) {
                            if (
                              value !== undefined &&
                              /^[\w-\.]+@([\w-]+\.)+[\w-]{3,3}$/.test(
                                value
                              )
                            ) {
                              return new Promise((resolve, reject) => {
                                axios
                                  .get(`${userApiUrl}/?email=${value}`)
                                  .then((res) => {
                                    // if adding id will be undefined
                                    if (
                                      id === undefined &&
                                      res.status === 200 &&
                                      res.data.length === 0
                                    ) {
                                      resolve(true)
                                    }
                                    // if editing id is not undefined
                                    if (
                                      id !== undefined &&
                                      res.status === 200 &&
                                      res.data.length === 1 &&
                                      parseInt(id) ===
                                      parseInt(res.data[0].userId)
                                    ) {
                                      resolve(true)
                                    }

                                    resolve(false)
                                  })
                              })
                            }
                          }
                        ),
                      phone: Yup.string()
                        .trim()
                        .matches(phoneRegExp, 'Phone number is not valid'),
                    })}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      setSubmitting(true)
                      let data = {
                        firstName: values.firstName,
                        lastName: values.lastName,
                        username: values.username,
                        email: values.email,
                        phone: values.phone,
                        updatedBy: user.username,
                        roleName: values.roleName,
                        // createdBy: values.createdBy,
                      }
                      let method = ''
                      let url = ''
                      if (id) {
                        data['UserId'] = id
                        method = 'put'
                        url = `${userApiUrl}/${id}/`
                      } else {
                        // data['createdBy'] = user.username
                        method = 'post'
                        url = `${userApiUrl}/`
                      }
                      addEditFetch({
                        method,
                        url,
                        data,
                      })
                      setSubmitting(false)
                    }}
                  >
                    {(props) => {
                      const {
                        values,
                        touched,
                        errors,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                      } = props

                      return (
                        <form onSubmit={handleSubmit} autoComplete="off">
                          <div>
                            {id ? (
                              <>
                                <h4>User ID</h4>
                                <span>{userData.userId}</span>
                              </>
                            ) : null}
                          </div>
                          <div>
                            <TextBox
                              errorText={
                                touched.firstName ? errors.firstName : null
                              }
                              id="firstName"
                              label={
                                <Label
                                  error={
                                    touched.firstName && errors.firstName
                                  }
                                  htmlFor="firstName"
                                >
                                  First Name
                                </Label>
                              }
                              onChange={handleChange}
                              onBlur={handleBlur}
                              required
                              value={values.firstName}
                              width="100%"
                              placeholder="Type here"
                              max="100"
                            />
                          </div>
                          <div>
                            <TextBox
                              errorText={
                                touched.lastName ? errors.lastName : null
                              }
                              id="lastName"
                              label={
                                <Label
                                  error={
                                    touched.lastName && errors.lastName
                                  }
                                  htmlFor="lastName"
                                >
                                  Last Name
                                </Label>
                              }
                              onChange={handleChange}
                              onBlur={handleBlur}
                              required
                              value={values.lastName}
                              width="100%"
                              placeholder="Type here"
                            />
                          </div>
                          <div>
                            {id && userData.username ? (
                              <>
                                <h4>User Name</h4>
                                <span>{userData.username}</span>
                              </>
                            ) : (
                              <TextBox
                                errorText={
                                  touched.username ? errors.username : null
                                }
                                id="username"
                                label={
                                  <Label
                                    error={
                                      touched.username && errors.username
                                    }
                                    htmlFor="username"
                                  >
                                    User Name
                                  </Label>
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required
                                value={values.username}
                                width="100%"
                                placeholder="Type here"
                              />
                            )}
                          </div>
                          <div>
                            {id ? (
                              <>
                                <h4>Email</h4>
                                <span>{userData.email}</span>
                              </>
                            ) : (
                              <TextBox
                                errorText={
                                  touched.email ? errors.email : null
                                }
                                id="email"
                                label={
                                  <Label
                                    error={touched.email && errors.email}
                                    htmlFor="email"
                                  >
                                    Email
                                  </Label>
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required
                                value={values.email}
                                width="100%"
                                placeholder="@"
                              />
                            )}
                          </div>
                          <div>
                            <TextBox
                              errorText={
                                touched.phone ? errors.phone : null
                              }
                              id="phone"
                              label={
                                <Label
                                  error={touched.phone && errors.phone}
                                  htmlFor="phone"
                                >
                                  Phone
                                </Label>
                              }
                              onChange={handleChange}
                              onBlur={handleBlur}
                              required
                              value={values.phone}
                              width="100%"
                              placeholder="#"
                            />
                          </div>
                          <div>
                            <DropDownList
                              errorText={errors.access}
                              id="roleName"
                              name="roleName"
                              label={
                                <Label
                                  error={errors.access}
                                  htmlFor="roleName"
                                >
                                  Role
                                </Label>
                              }
                              onChange={handleChange}
                              onBlur={handleBlur}
                              width="100%"
                            >
                              <DropDownList.Item value="">
                                Choose a role ...
                              </DropDownList.Item>
                              {rolesData.map((role, roleIndex) => {
                                return (
                                  <DropDownList.Item
                                    value={role.name}
                                    selected={
                                      id !== undefined &&
                                      role.name === userData.roleName
                                    }
                                  >
                                    {role.name}
                                  </DropDownList.Item>
                                )
                              })}
                            </DropDownList>
                          </div>
                          {id ? (
                            <>
                              {/* <div>
                                    <h4>Created By</h4>
                                    <span>{userData.created_by}</span>
                                  </div>
                                  <div>
                                    <h4>Date Created</h4>
                                    <span>
                                      {moment(userData.created_at)
                                        .tz(user.preferences.timeZone)
                                        .format('L, LTS z')}
                                    </span>
                                  </div> */}
                              <div>
                                <h4>Edited By</h4>
                                <span>{userData.updated_by}</span>
                              </div>
                              <div>
                                <h4>Date Updated</h4>
                                <span>
                                  {moment(userData.updated_at)
                                    .tz(user.preferences.timeZone)
                                    .format('L, LTS z')}
                                </span>
                              </div>
                            </>
                          ) : null}

                         
                         <div style={{float:'right'}}> <ButtonList>

                            <Button
                              onClick={() => {
                                history(`/admin/users`)
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="submit"
                              disabled={!isEditVisible(userData.roleName)}
                            >
                              {id ? 'Save Changes' : 'Add'}
                            </Button>
                          </ButtonList>
                          </div>
                        </form>
                      )
                    }}
                  </Formik>
                </div>
              </div>
            </Flex.Item>
          ) : null}
        </Flex>
      </div>
    </div>
    //   </Content>
    // </Layout>
  )
}

export default AddEditUser
