import moment from 'moment'
function getBorderColor(color)
{
  let bColor="#FFFFFF"
  switch(color)
  {
    case '#C40000':
      bColor="#FFFFFF"
      break;
    case "#FB8D7B":
      bColor="#990000"
      break;
    case "#FFFFF0":
      bColor="#AD8D01"
      break;
    case "#6CB2A2":
      bColor="#0D5E4F"
      break;
    case "#218371":
      bColor="#FFFFFF"
      break;
    case "#6D6F70":
      bColor="#6D6F70"
      break;
    
    case "#48338C":
      bColor="#48338C"
      break;
    case "#5768B0":
      bColor="#5768B0"
      break;
    case "#7B99CD":
      bColor="#0E456B"
      break;
    case "#ACCAE4":
      bColor="#2D6EA3"
      break;
    case "#E5FCF6":
      bColor="#2B9181"
      break;
    
  }
  return bColor
}
function toolTipFormatter() {
  let imageName = (this.point.istrue)? "trending_up.png":"trending_down.png"
  var url =  `${window.location.origin}/${imageName}`; 
  let threshold = '' 
  let stokeColor = getBorderColor(this.point.color)
  let fillColor = this.point.color
  let color = '#007000'
  let divData1=''
  if (this.point.istrue) 
  {color = '#C40000'}
  
 if(this.point.data_value<=0)
{
  divData1=`<div style="padding-top:16px;padding-left: 16px;padding-right: 16px;height:80px;background-color:white;font-family:Optum Sans;border: 1px solid #CBCCCD;
  border-radius: 4px;">
  <div style="width:90%; padding-top:16px;padding-right:5px;display:inline;
  color: #002677;font-family:'Optum Sans';font-style: normal;font-weight: 700;
  font-size: 14px;line-height: 18px;
  ">${this.point.geo_name} </div><br/>
  <span style="font-style: normal; padding-top:8px;font-weight: 700;
  font-size:14px; line-height: 18px; display: flex; align-items: center;
  color: #4B4D4F;">Epiweek ${
    this.point.epiweek
  }: ${moment(this.point.week_ending).format('MMM DD, YYYY')}</span>
  <div style="padding-top:8px;color: #002677;font-family:'Optum Sans';font-style: normal;font-weight: 700;
  font-size: 14px;">No Data
  </div>
  </div>`
}
else{
  divData1= `<div style="padding-top:16px;padding-left: 16px;padding-right: 16px;height:160px;background-color:white;font-family:Optum Sans;border: 1px solid #CBCCCD;
  border-radius: 4px;">
  <div style="width:90%; padding-top:16px;padding-right:5px;display:inline;
  color: #002677;font-family:'Optum Sans';font-style: normal;font-weight: 700;
  font-size: 14px;line-height: 18px;
  ">${this.point.geo_name} </div>
  <br/>
  <span style="font-style: normal; padding-top:8px;font-weight: 700;
  font-size:14px; line-height: 18px; display: flex; align-items: center;
  color: #4B4D4F;">Epiweek ${
    this.point.epiweek
  }: ${moment(this.point.week_ending).format('MMM DD, YYYY')}</span>
  <div style="padding-top:8px;">
  <div style="border-radius:0%;background-color:${fillColor}; 
  display: inline-block; width:16px; height:16px;border:1px solid ${stokeColor}"></div>&nbsp;
  <div style='display: inline-block; font-size:14px;font-weight:400;vertical-align:super;padding-left:10px'>${threshold}</div>
  </div>
  <div>
  <span style="font-style: normal; padding-top:8px;font-weight: 400;
  font-size:14px; line-height: 18px; display: flex; align-items: center;
  color: #4B4D4F;">Incidence :<span style="font-family: 'Optum Sans', sans-serif;
  font-style:normal;font-weight: 700;font-size: 14px;
  line-height:18px;display: flex;align-items:center;
  color: #4B4D4F;padding:2px;
  ">${parseFloat(this.point.data_value).toFixed(3)}</span></span>                
   </div>    
   <div style="
   width: 157px;
   padding-top: 8px;
   padding-bottom: 16px;
   font-family: 'Optum Sans', sans-serif;
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   line-height: 18px;
   display: flex;
   align-items: center;
   color: #4B4D4F;
   ">

   Change : 
  <div  style="font-weight:bold; color:${color};padding:2px; display:flex;">    
  
  <div style="height:18px; width:16px; margin-top:0px;margin-left:5px">
  
  <img style="height:20px;width:20px;padding-right:5px;" src="${url}"/>
  </div>
  <div style="margin-left:10px;margin-right:4px"> ${
    parseFloat(this.point.change_actual_percentage).toFixed(2)
  }% 
  </div>
  </div>     
  </div>
  </div>`
}
  
  return divData1
}

export default toolTipFormatter
