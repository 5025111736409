import { useNavigate } from 'react-router-dom'
// move this to another file
import Highcharts from 'highcharts'
import HT from 'highcharts/highcharts-more'
import HighchartsReact from 'highcharts-react-official'

import { useGeoState } from '../../../../components/GeoProvider'
import Spinner from '../../../../components/Loader/Spinner'
import Icon from '../../../../components/Icon'

import statesMap from '../../../../utils/stateMap'
import cbsasMap from '../../../../utils/cbsasMap'

import { epiweekToolTip } from './toolTipFormatterFlu'
import AccuracyTable from '../../../../components/AccuracyTable'
import dataDisplayOptions from './dataDisplayOptions'
import modelOptions from './fluModelOptions'

import styles from './FluDetailedView.module.scss'

if (typeof Highcharts === 'object') {
  HT(Highcharts)
}
const DetailedViewDisplay = ({
  cbsaData,
  statesData,
  type,
  identifier,
  cbsaGraphData,
  stateGraphData,
}) => {  
  const { dataSelected } = useGeoState()
  let dataObj = []
  if (type === 'state') {
    Object.entries(stateGraphData).forEach(function (dateObj) {
      const key = dateObj[0]
      const value = dateObj[1][identifier]
      dataObj.push({ date: key, ...value })
    })
  } else {
    Object.entries(cbsaGraphData).forEach(function (dateObj) {
      const key = dateObj[0]
      const value = dateObj[1][identifier]
      dataObj.push({ date: key, ...value })
    })
  }

  let revised = [],
    unrevised = [],
    pointforecast = [],
    percent95 = [],
    weeks = []
// i value changed from 6 to 4 for flu pharma change
  for (var i = 1; i <= 4; i++) {
    var per95 = dataObj.map((d) => {
      return [d[i].low95, d[i].high95]
    })
    var rev = dataObj.map((d) => {
      return [d[i].revised_flu]
    })
    var unrev = dataObj.map((d) => {
      return [d[i].unrevised_flu]
    })
    var point = dataObj.map((d) => {
      return [d[i].pointforecast]
    })
    percent95.push(per95)
    revised.push(rev)
    unrevised.push(unrev)
    pointforecast.push(point)

    per95 = null
    rev = null
    unrev = null
    point = null
  }

  dataObj.map((d) => {
    weeks.push(d[4].epiweek)  // d value changed from 5 to 4 for flu pharma change
  })
  // let dataOptions = null
  // if (dataDisplayOptions[0].options[0].value == dataSelected) {
  //   dataOptions = dataDisplayOptions[0].options[0]
  // } else {
  //   dataOptions = dataDisplayOptions[0].options[1]
  // }
  let data = []
  if (type === 'state') {
    const statesDataForDisplayDate = Object.entries(
      statesData[modelOptions[0].value]
    )
    Object.entries(statesDataForDisplayDate).forEach(function (dateObj) {
      if (dateObj[1][0] === identifier) {
        data.push({
          error_metric: 'MAE',
          revised: dateObj[1][1].OVERALL.revised_mae,
          unrevised: dateObj[1][1].OVERALL.unrevised_mae,
        })
        data.push({
          error_metric: 'MAPE',
          revised: dateObj[1][1].OVERALL.revised_mape,
          unrevised: dateObj[1][1].OVERALL.unrevised_mape,
        })

        data.push({
          error_metric: 'RMSE',
          revised: dateObj[1][1].OVERALL.revised_rmse,
          unrevised: dateObj[1][1].OVERALL.unrevised_rmse,
        })
        data.push({
          error_metric: 'MASE',
          revised: dateObj[1][1].OVERALL.revised_mase,
          unrevised: dateObj[1][1].OVERALL.unrevised_mase,
        })
      }
    })
  } else {
    const cbsasDataForDisplayDate = Object.entries(
      cbsaData[modelOptions[0].value]
    )
    Object.entries(cbsasDataForDisplayDate).forEach(function (dateObj) {
      if (dateObj[1][0] === identifier) {
        data.push({
          error_metric: 'MAE',
          revised: dateObj[1][1].OVERALL.revised_mae,
          unrevised: dateObj[1][1].OVERALL.unrevised_mae,
        })
        data.push({
          error_metric: 'MAPE',
          revised: dateObj[1][1].OVERALL.revised_mape,
          unrevised: dateObj[1][1].OVERALL.unrevised_mape,
        })
        data.push({
          error_metric: 'RMSE',
          revised: dateObj[1][1].OVERALL.revised_rmse,
          unrevised: dateObj[1][1].OVERALL.unrevised_rmse,
        })
        data.push({
          error_metric: 'MASE',
          revised: dateObj[1][1].OVERALL.revised_mase,
          unrevised: dateObj[1][1].OVERALL.unrevised_mase,
        })
      }
    })
  }

  const chartOptions = {
    title: {
      text: undefined,
    },
    xAxis: {
      categories: weeks,
      title: {
        text: 'Week of Flu Season',
      },
    },
    yAxis: {
      title: {
        text: '% Flu2 Intensity',
      },
      min: 0,
    },
    tooltip: {
      crosshairs: true,
      shared: true,
      useHTML: true,
      padding: 0,
      formatter: epiweekToolTip,
    },
    credits: {
      enabled: false,
    },
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
    },
    plotOptions: {
      line: {
        marker: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: 'Revised',
        data: revised[0],
        zIndex: 1,
        color: '#1192E8',
      },
      //Below code commented temporarly, Need to make working once unrevised data available 
      // {
      //   name: 'UnRevised',
      //   data: unrevised[0],
      //   zIndex: 1,
      //   color: '#6929C4',
      // },

      {
        name: 'Horizon 1',
        data: percent95[0],
        type: 'arearange',
        lineWidth: 0,
        color: '#a4d4ff7a',
        fillOpacity: 0.5,
        zIndex: 0,
        showInLegend: true,
        marker: {
          enabled: false,
        },
      },

      {
        name: 'Horizon 2',
        data: percent95[1],
        type: 'arearange',
        lineWidth: 0,
        color: '#c7baee',
        fillOpacity: 0.25,
        zIndex: 0,
        showInLegend: true,
        marker: {
          enabled: false,
        },
      },

      {
        name: 'Horizon 3',
        data: percent95[2],
        type: 'arearange',
        lineWidth: 0,
        color: '#79e0607a',
        fillOpacity: 0.25,
        zIndex: 0,
        showInLegend: true,
        marker: {
          enabled: false,
        },
      },

      {
        name: 'Horizon 4',
        data: percent95[3],
        type: 'arearange',
        lineWidth: 0,
        color: '#bacaee',
        fillOpacity: 0.25,
        zIndex: 0,
        showInLegend: true,
        marker: {
          enabled: false,
        },
      },

      // Horizon 5 and Horizon 6 commented for flu pharma change
      // {
      //   name: 'Horizon 5',
      //   data: percent95[4],
      //   type: 'arearange',
      //   lineWidth: 0,
      //   color: '#cae2c6',
      //   fillOpacity: 0.25,
      //   zIndex: 0,
      //   showInLegend: true,
      //   marker: {
      //     enabled: false,
      //   },
      // },

      // {
      //   name: 'Horizon 6',
      //   data: percent95[5],
      //   type: 'arearange',
      //   lineWidth: 0,
      //   color: '#dfe2e4',
      //   fillOpacity: 0.25,
      //   zIndex: 0,
      //   showInLegend: true,
      //   marker: {
      //     enabled: false,
      //   },
      // },
    ],
  }
  return (
    <>
      <div>
        <HighchartsReact
          containerProps={{
            style: {
              width: '100%',
              position: 'relative',
            },
          }}
          highcharts={Highcharts}
          options={chartOptions}
          allowChartUpdate
          updateArgs={[true, true, true]}
        />
      </div>
      <AccuracyTable data={data} />
    </>
  )
}

const DetailedView = ({
  cbsaData,
  statesData,
  hash,
  cbsaGraphData,
  stateGraphData,

  cbsaJson,

}) => {
  const history = useNavigate()
  const area = hash.replace('#', '').split('-')
  const identifier = area[1]
  const type = area[0]
  let name = ''
  let displayType = type

  switch (type) {
    case 'state':
      name = statesMap()[identifier]
      break
    case 'metro':
    case 'msa':
      name = cbsasMap(cbsaJson)[identifier]
      displayType = 'Metro'
      break
    case 'county':
      break
    default:
    // code block
  }

  return (
    <div className={styles.base}>
      <div className={styles.header}>
        <div>
          <h5>{name}</h5>
        </div>
        <button
          onClick={() => {
            history(`/about/model#accuracy`)
          }}
        >
          <Icon type="remove" size={'1rem'} />
        </button>
      </div>
      <div className={styles.body}>
        {(type === 'state' &&
          stateGraphData !== undefined &&
          Object.keys(stateGraphData).length >= 1) ||
        (type === 'msa' &&
          cbsaGraphData !== undefined &&
          Object.keys(cbsaGraphData).length >= 1) ? (
          <DetailedViewDisplay
            cbsaData={cbsaData}
            statesData={statesData}
            hash={hash}
            type={type}
            identifier={identifier}
            cbsaGraphData={cbsaGraphData}
            stateGraphData={stateGraphData}
          />
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  )
}

export default DetailedView
