import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { useGeoState, useGeoFetch } from '../../../components/GeoProvider'
import ErrorMessage from '../../../components/Layout/ErrorMessage'
import Spinner from '../../../components/Loader/Spinner'
import MapOptions from '../../../components/MapOptions'
import EmptyMap from '../../../components/Map/emptyMap'
import TimelineHrr from '../../../components/TimelineHrr'
import getRegionDisplayOptions from './regionDisplayOptions'
import dataDisplayOptions from './dataDisplayOptions'
import CovidDetailedView from './CovidDetailedView'
import CovidHRRMap from './CovidHRRMap'
import HrrStats from './HrrStats'
import LegendCasesWeeklyHrr from './LegendCasesWeeklyHrr'
import styles from './Covid19Geo.module.scss'
import DisplayDate from '../../../components/DisplayDateHrr'

import { hrrdate, hrrenddate,displaydatehrr, startDateHrr, endDateHrr } from '../dates'
const HRRGeo = () => {
  const entity = 'covid'
  const slotTimeInSeconds = 604800000 //86400000
  const latestPositionString = '' //'Today'
  const slotString = 'week' //'day'

  const location = useLocation()
  const hash = location.hash

  const [publicHRRs, setPublicHRRs] = useState()
  const [errorCode, setErrorCode] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [errorSubject, setErrorSubject] = useState()
  const [errorOccured, seterrorOccured] = useState(false)

  const { mapOptions, setRegionSelected, setHrrEndDate, hrrDataWeekEndDate } = useGeoState()

  
  

  if (hash) {
    setRegionSelected(hash.split('-')[0].replace('#', ''))
  }

  //HRR Data
  // const { data: publicHRRTodayData, error: publicHRRTodayDataError } =
  //   useGeoFetch(hrrenddate, hrrenddate, entity + '/Hospitalization', 'hrr')

  // useEffect(() => {
  //   if (publicHRRTodayData !== undefined) {
  //     if (publicHRRTodayData.response_code === 200) {
       
  //       setPublicHRRs(publicHRRTodayData.response_data)
  //     }
  //   }
  // }, [publicHRRTodayData])

  const { data: publicHRRData, error: publicHRRDataError } = useGeoFetch(
    startDateHrr,
    endDateHrr,
    entity + '/Hospitalization',
    'hrr'
  )
//
  useEffect(() => {
    if (publicHRRData !== undefined) {
      if (publicHRRData.response_code === 200) {
        let hrr_date = Object.keys(publicHRRData.response_data)[0]
        setPublicHRRs(publicHRRData.response_data)
        setHrrEndDate(hrr_date)
      }
    }
  }, [publicHRRData])

  const [getJsonData, setJsonData] = useState();
  useEffect(() => {
    let url = `${window.location.origin}/us_hrr_values.json`
    fetch(url)
      .then(function (res) {       
        return res.json();
      })
      .then(function (data) {
       setJsonData(data);
      })
      .catch(function (err) {
        console.log(err, " error file load us_hrr_values ");
      });
  }, [1]);

  if ( publicHRRDataError || errorOccured) {
    return (
      <ErrorMessage
        errorCode={errorCode}
        errorMessage={errorMessage}
        errorSubject={errorSubject}
      />
    )
  }

  const getData = (config) => {
    switch (config) {
      case 'hrrData':
        return mapOptions['parent'] === 'Covid Hospitalization'
          ? publicHRRs
          : ''
      default:
        return ''
    }
  }

  const getPositionString = () => {
    switch (mapOptions['parent']) {
      case 'Covid Hospitalization':
        return latestPositionString
    }
  }

  const getMapLegend = () => {
    switch (mapOptions['value']) {
      case 'hospital_admissions': //'hospital_admissions':
        return <LegendCasesWeeklyHrr />
      default:
        return
    }
  }
 
  const setValueEndDateHrr = (data) => {
    hrrdate = data
    hrrenddate= data
    //setHrrEndDate(data)
  };


  return (
    <>
      <div className={`${styles.base}`}>
        <div className={`${styles.mapfilter}`}>
          <MapOptions
            regionDisplayOptions={getRegionDisplayOptions(mapOptions['parent'])}
            dataDisplayOptions={dataDisplayOptions}
            exportData={null} // below data section added by Avanish
            mapType="HRR"
          />
        </div>
        <div className={`${styles.covidheader}`}>
          <p
            style={{
              // height: '0px',
              marginBottom: '5px',
              fontFamily: 'graphik',
              fontSize: '21px',
              color: '#424242',
              fontWeight: 500,
            }}
          >
            Hospital Admissions COVID-19
          </p>
          <span
            style={{
              fontFamily: 'graphik',
              fontSize: '14px',
              color: '#424242',
              fontStyle: 'nornal',
              lineHeight: '16px',
              fontWeight: '400px',
            }}
          >
            Actual and forecasted hospital admissions are based on the{' '}
            <b>health and human services</b> data.
          </span>
        </div>

        <div className={`${styles.map}`}>
          {'enabled' in mapOptions.legend &&
            mapOptions.legend.enabled === false &&
            getMapLegend()}

          {publicHRRs !== undefined  && getJsonData  ? (
            <CovidHRRMap
              hrrData={getData('hrrData')}
              mapOptions={mapOptions}
              toolTipFormatter={mapOptions['toolTip']}
              hrrMapJson={getJsonData}              
             
            />
          ) : (
            <EmptyMap />
          )}
        </div>
        <div className={`${styles.datePanel}`}>
          <div className={`${styles.cbsasPanel}`}>
            {hash && getJsonData &&  (
              <div className={styles.mapPopup}>
                <CovidDetailedView
                  cbsaData={null}
                  statesData={null}
                  countyData={null}
                  msaData={null}
                  hrrData={getData('hrrData')}
                  hash={hash}
                  hrrMapJson={getJsonData}
                />
              </div>
            )}

            {mapOptions['parent'] !== 'UHC Indicators' && (
              <>
                {publicHRRs !== undefined && getJsonData? (
                  <HrrStats hrrMapJson={getJsonData} cbsaData={publicHRRs} mapOptions={mapOptions} />
                ) : (
                  <Spinner />
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className={`${styles.timelinePanel}`}>
        {publicHRRs !== undefined && (
          <DisplayDate
            showWeek={false}
            latestPositionString={latestPositionString}
            slotString={slotString}
            slotTimeInSeconds={slotTimeInSeconds}
            hrrDate={hrrdate}
            hrrEndDate={hrrenddate}
          />
        )}

        {publicHRRs !== undefined && hrrDataWeekEndDate && (
          <TimelineHrr
            statesData={getData('hrrData')}
            latestPositionString={getPositionString()}
            slotString={slotString}
            slotTimeInSeconds={slotTimeInSeconds}
            markCounts={5}
            hrrDate={hrrdate}
            hrrEndDate={hrrenddate}
          />
        )}
      </div>
    </>
  )
}

export default HRRGeo
