import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
// move this to another file
import Highcharts from 'highcharts'
import HT from 'highcharts/highcharts-more'
import HighchartsReact from 'highcharts-react-official'
import moment from 'moment'

import { useGeoState } from '../../../../components/GeoProvider'
import Spinner from '../../../../components/Loader/Spinner'
import LinkButton from '../../../../components/LinkButton'
import Icon from '../../../../components/Icon'

import statesMap from '../../../../utils/stateMap'
import cbsasMap from '../../../../utils/cbsasMap'
import BaseLine from '../../../../components/BaseLine'

import { dateToolTip } from '../../toolTipFormatterFlu'

import styles from './FluDetailedView.module.scss'
import CheckBox from '../../../../components/CheckBox'
if (typeof Highcharts === 'object') {
  HT(Highcharts)
}

const DetailedViewDisplay = ({ cbsaData, statesData, type, identifier }) => {
  let data = []
  const [getactualflag, setactualflag] = useState(true)
  const [getBaselineflag, setBaselineflag] = useState(true)
  const [getforecastflag, setforecastflag] = useState(true)
  const { displayDate } = useGeoState()
  const history = useNavigate()
  function handleChange(e) {
    let isChecked = e.target.checked
    let chkValue = e.target.value
    if (chkValue === 'BaseLine' && isChecked === true) {
      setBaselineflag(true)
    } else if (chkValue === 'BaseLine' && isChecked === false) {
      setBaselineflag(false)
    }
    if (chkValue === 'Forecast' && isChecked === true) {
      setforecastflag(true)
    } else if (chkValue === 'Forecast' && isChecked === false) {
      setforecastflag(false)
    } else if (chkValue === 'Actual' && isChecked === true) {
      setactualflag(true)
    } else if (chkValue === 'Actual' && isChecked === false) {
      setactualflag(false)
    }
    var filterData = data.filter((t) => t.cast_type === chkValue)
  }

  if (type === 'state') {
    Object.entries(statesData).forEach(function (dateObj) {
      const key = dateObj[0]
      const value = dateObj[1][identifier]
      data.push({ date: key, ...value })
    })
  } else {
    Object.entries(cbsaData).forEach(function (dateObj) {
      const key = dateObj[0]
      const value = dateObj[1][identifier]
      data.push({ date: key, ...value })
    })
  }

  const percent80 = data.map((d) => {
    return [new Date(d.date).getTime(), d.ci_low80, d.ci_high80]
  })

  const percent95 = data.map((d) => {
    return [new Date(d.date).getTime(), d.ci_low95, d.ci_high95]
  })

  const forecast = data.map((d) => {
    return [new Date(d.date).getTime(), d.forecast_value]
  })

  const actual = data
    .filter((d) => d.actual_value > 0)
    .map((d) => {
      return [new Date(d.date).getTime(), d.actual_value]
    })

  const high = data.map((d) => {
    return [new Date(d.date).getTime(), d.high_point]
  })

  const chartOptions = {
    title: {
      text: undefined,
    },
    xAxis: {
      type: 'datetime',
      accessibility: {
        rangeDescription: 'Date Range',
      },
      plotLines: [
        {
          color: '#cdcdcd',
          width: 1,
          value: new Date(displayDate).getTime(),
          zIndex: 10,
          label: {
            x: -20,
            rotation: 0,
            text: moment(displayDate).format('MMM D'),
            style: { color: 'black' },
          },
        },
      ],
    },
    yAxis: {
      title: {
        text: 'Flu2',
      },
      min: 0,
    },
    tooltip: {
      crosshairs: true,
      shared: true,
      useHTML: true,
      padding: 0,
      formatter: dateToolTip,
    },
    credits: {
      enabled: false,
    },
    legend: {
      layout: 'horizontal',
      align: 'left',
      verticalAlign: 'bottom',
    },
    plotOptions: {
      line: {
        marker: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: 'Baseline',
        data: high,
        zIndex: 1,
        color: 'red',
        visible: getBaselineflag,
      },
      {
        name: 'Forecast',
        data: forecast,
        zIndex: 1,
        color: Highcharts.getOptions().colors[0],
        visible: getforecastflag,
      },
      {
        name: 'Actual',
        data: actual,
        zIndex: 1,
        color: 'black',
        visible: getactualflag,
      },

      {
        name: '95% Confidence level',
        data: percent95,
        type: 'arearange',
        lineWidth: 0,
        linkedTo: ':previous',
        color: Highcharts.getOptions().colors[0],
        fillOpacity: 0.25,
        zIndex: 0,
        showInLegend: true,
        marker: {
          enabled: false,
        },
      },
      {
        name: '80% Confidence level',
        data: percent80,
        type: 'arearange',
        lineWidth: 0,
        linkedTo: ':previous',
        color: Highcharts.getOptions().colors[0],
        fillOpacity: 0.4,
        zIndex: 0,
        showInLegend: true,
        marker: {
          enabled: false,
        },
      },
    ],
  }

  return (
    <>
      <div className={styles.alignLeft}>
        <BaseLine></BaseLine>
      </div>
      <div className={styles.checkboxPosition}>
        <CheckBox
          label={'BaseLine'}
          value={'BaseLine'}
          id={'BaseLine'}
          //selected={true}
          className={styles.checkboxText}
          onChange={(e) => handleChange(e)}
          defaultChecked={true}
        ></CheckBox>

        <CheckBox
          label={'Actual'}
          value={'Actual'}
          id={'Actual'}
          //checked="selected"
          className={styles.checkboxText}
          onChange={(e) => handleChange(e)}
          defaultChecked={true}
        ></CheckBox>

        <CheckBox
          label={'Forecast'}
          value={'Forecast'}
          id={'Forecast'}
          //checked="selected"
          className={styles.checkboxText}
          onChange={(e) => handleChange(e)}
          defaultChecked={true}
        ></CheckBox>
      </div>

      <div>
        {/* <h4>Flu Trends</h4> */}
        <HighchartsReact
          containerProps={{
            style: {
              width: '100%',
              position: 'relative',
            },
          }}
          highcharts={Highcharts}
          options={chartOptions}
          allowChartUpdate
          updateArgs={[true, true, true]}
        />
      </div>
    </>
  )
}

const DetailedView = ({ cbsaData, statesData, hash, stateJson, cbsaJson }) => {
  const history = useNavigate()
  const area = hash.replace('#', '').split('-')
  const identifier = area[1]
  const type = area[0]

  let name = ''
  let displayType = type
  switch (type) {
    case 'state':
      name = statesMap(stateJson)[identifier]
      break
    case 'metro':
    case 'msa':
    case 'city':
      name = cbsasMap(cbsaJson)[identifier]
      displayType = 'Metro'
      break
    case 'county':
      // name = countyMap()[identifier]
      break
    default:
    // code block
  }

  return (
    <div className={styles.base}>
      <div className={styles.header}>
        <div>
          <h3>
            {name}{' '}
            <LinkButton
              className={styles.viewMore}
              onClick={() => {
                history(`flu/${type}/${identifier}`)
              }}
              link
            >
              View more
            </LinkButton>
          </h3>

          {/* <span className={styles.position}>{displayType}</span> */}
        </div>
        <button
          onClick={() => {
            history({ hash: null })
          }}
        >
          <Icon type="remove" size={'1.5rem'} />
        </button>
      </div>

      <div className={styles.body}>
        {(type === 'state' &&
          statesData !== undefined &&
          Object.keys(statesData).length > 1) ||
        (type === 'metro' &&
          cbsaData !== undefined &&
          Object.keys(cbsaData).length > 1) ? (
          <DetailedViewDisplay
            cbsaData={cbsaData}
            statesData={statesData}
            hash={hash}
            type={type}
            identifier={identifier}
          />
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  )
}

export default DetailedView
