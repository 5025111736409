import colorAxisFluIndex from './colorAxisFluIndex'
import colorAxisVulIndex from './colorAxisCoverageIndex'
const dataDisplayOptions = [
  {
    label: 'Public Indicators',
    options: [
      {
        value: 'forecast_level',
        tagKey: 'forecast_level',
        tagValue: 'data_value',
        label: 'Incidense Rate',
        colorAxis: colorAxisFluIndex,
        mapTitle: 'US RSV Incidence',
        mapSubTitle: undefined,
        indicatorName: 'Forecasts',
        legend: {
          enabled: false,
          title: {
            text: 'US RSV Incidence',
          },
        },
      },
      {
        value: 'state_percentage',
        tagKey: 'state_percentage',
        tagValue: 'state_percentage',
        stateCount: 'total_state_count',
        statePer: 'state_percentage',
        lobValue: 'total_state_lob_count',
        lobPer: 'lob_percentage',
        popCount: 'population_count',
        label: 'Coverage',
        // colorAxis: cdcMapLegendHorizontal,
        mapTitle: 'UHC Coverage ',
        colorAxis: colorAxisVulIndex,
        legend: {
          enabled: false,
          title: {
            text: 'UHC Coverage',
          },
        },
      },
    ],
  },
]

export default dataDisplayOptions
