import React from 'react'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { useGeoState } from '../GeoProvider'

import styles from './DisplayDate.module.scss'
import Tooltip from '../Tooltip'
import Content from '../Content'

export const DisplayDateCovid = ({
  slotTimeInSeconds,
  showWeek,
}) => {
  const history = useNavigate()
  const {
    loadedDate,
    displayDate,
    displayWeek,
    setDisplayDate,
    setTimelineIsPaused,
    hrrDataWeekEndDate,
  } = useGeoState()

  // TODO : this const number should be moved out at the /Flu level to be used also for the <DisplayDate /> component

  const timeSpan =
    (new Date(displayDate) - new Date(loadedDate)) / slotTimeInSeconds

  const aboutText = 'The weekly forecast of COVID-19 hospitalizations per Hospital Referral Region (HRR), and the actual hospitalization counts for previous weeks.  The HRRs are made up of zip code area groupings based on the referral patterns of tertiary medical care.'
  // TODO: can break this into a common function for the display date and this component to use!

  let position = ''
  

  return (
    <div className={styles.base}>
      <div className={styles.dateWeek}>
        <div className={styles.infoIcon}>
          <Tooltip placement="top">
            <Content maxWidth="300px" fontSize="12px">
              <p>{aboutText}</p>
            </Content>
          </Tooltip>
          <span className={styles.aboutThis}>
            <a
              href=""
              onClick={() => {
                history('/about/model')
              }}
            >
              About Models
            </a>
          </span>

          <span className={styles.displayDateMargin}>
            {'Viewing Week of ' +
              moment(hrrDataWeekEndDate).format('MMMM D, YYYY')}{' '}
            {showWeek ? `- Epiweek ${displayWeek}` : null}
          </span>
          
        </div>

        <div className={styles.setHeight}>
          <span className={styles.position}>{position}</span>

          {timeSpan !== 0 && (
            <button
              className={styles.positionButton}
              onClick={(e) => {
                e.preventDefault()
                setDisplayDate(loadedDate)
                setTimelineIsPaused(true)
              }}
            >        
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default DisplayDateCovid
