import React, { useEffect, useState } from 'react'
import styles from './AdminHeader.module.scss'
import { useNavigate } from 'react-router-dom'


const AdminHeader = () => {
  const history = useNavigate()
  const onUserPageClick = () => {
    history(`/admin/users`)
}
const onNotificationPageClick = () => {
  history(`/admin/systemnotifications`)
}
const onSubsPageClick = () => {
  history(`/admin/subscriptions`)
}
const onActivityPageClick = () => {
  history(`/admin/usersactivity`)
}

  return (
    <div className={styles.base}>
      <div className={styles.mainDivPanel}>      
        <div className={styles.link}>
        
        <a onClick={onUserPageClick}> Users</a>
        </div>
        <div className={styles.link}>
       <a onClick={onNotificationPageClick}> System Notifications</a>
        </div>
        <div className={styles.link}>
        <a onClick={onSubsPageClick}> Subscriptions</a>  
        </div>
        <div className={styles.link}>
        <a onClick={onActivityPageClick}>  User Activity Metrics</a>   
        </div>
      
        
      </div> 
      <hr/>
     </div>
     
  )
}

export default AdminHeader
